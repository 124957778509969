import {Component, OnInit} from '@angular/core';
import {catchError, filter, first, switchMap} from 'rxjs/operators';
import {NEVER} from 'rxjs';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormControl, FormGroup} from '@angular/forms';
import {FormErrors} from '../../../models/abstract/formErrors';
import {UserService} from '../../../shared/services/user.service';
import {OrderService} from '../../../shared/services/order.service';
import {NavigationService} from '../../../shared/services/navigation.service';
import {StateService} from '../../../services/state.service';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'course-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    protected readonly FormErrors = FormErrors;
    hidePassword = true;
    hasLoginError = false;
    form = new FormGroup({
        email: new FormControl<string | null>(''),
        password: new FormControl<string | null>('')
    });
    errors: {[field: string]: string[]} = {};

    constructor(
        private userService: UserService,
        private router: Router,
        private snackBar: MatSnackBar,
        private orderService: OrderService,
        public navigationService: NavigationService,
        private stateService: StateService
    ) {}

    ngOnInit() {
        this.subscribeToActiveUser();
        // this.initLoginWithGoogle();
    }

    // initLoginWithGoogle() {
    //     const script = document.createElement('script');
    //     script.src = 'https://accounts.google.com/gsi/client?hl=nl';
    //     script.type = 'text/javascript';
    //     script.async = true;
    //     script.charset = 'utf-8';
    //     document.getElementsByTagName('head')[0].appendChild(script);
    // }

    subscribeToActiveUser() {
        this.stateService.getActiveUserObservable().pipe(
            filter(activeUser => !!activeUser),
            first()
        ).subscribe(
            (activeUser) => {
                this.router.navigate(['/']);
            }
        );
    }

    submit() {
        const email = this.form.get('email').value;
        const password = this.form.get('password').value;

        if (!email || !password) {
            if (!email) {
                this.errors.email = ['required'];
                this.form.get('email').setErrors({required: 'required'});
            }

            if (!password) {
                this.errors.password = ['required'];
                this.form.get('password').setErrors({required: 'required'});
            }

            return;
        }

        this.userService.login(email, password).pipe(
            first(),
            switchMap(
                (success: boolean) => {
                    return this.userService.getLoggedInUser();
                }
            ),
            catchError(
                (err) => {
                    if (
                        (err.status === 400 && err.error.error === 'invalid_grant')
                        || err.status === 404
                    ) {
                        this.hasLoginError = true;
                        return NEVER;
                    }

                    throw err;
                }
            )
        ).subscribe(
            (user) => {
                this.stateService.setCartItems(0);
                this.hasLoginError = false;
                this.snackBar.open(
                    $localize `:@@auth.welcomeBack:Welcome back` + ', ' + user.firstName + '!',
                    '',
                    {
                        duration: 5000,
                        panelClass: ['infoSnackbar']
                    }
                );
            }
        );

        return false;
    }

    protected readonly environment = environment;
}

