import {ApplicationRef, Directive, Injectable, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {SwUpdate, VersionReadyEvent} from '@angular/service-worker';
import {delay, filter, first, timeout} from 'rxjs/operators';
import {concat, interval, race, timer} from 'rxjs';
import {AppService} from '../shared/services/app.service';


/**
 * Update to a new version of the PWA if there is one available.
 * The update happens after navigation.
 */
@Injectable({
    providedIn: 'root',
})
export class UpdateVersionService extends OnDestroyMixin {
    isNewVersionAvailable = false;

    /**
     * Constructor
     */
    constructor(
        private router: Router,
        private swUpdate: SwUpdate,
        private appRef: ApplicationRef,
        private appService: AppService
    ) {
        super();
    }

    init() {
        if (this.appService.isBrowser) {
            this.pollForUpdates();
            this.initVersionUpdate();
        }
    }

    pollForUpdates() {
        // Allow the app to stabilize first, before starting polling for updates with `interval()`.
        const appIsStable = this.appRef.isStable.pipe(
            first(isStable => isStable === true)
        );
        const pollInterval = interval(10 * 60 * 1000); // 10min
        const everyIntervalOnceAppIsStable = concat(appIsStable, pollInterval);

        everyIntervalOnceAppIsStable.pipe(
            untilComponentDestroyed(this)
        ).subscribe(async () => {
            try {
                const updateFound = await this.swUpdate.checkForUpdate();
                // console.log(updateFound ? 'A new version is available.' : 'Already on the latest version.');
            } catch (err) {
                // console.error('Failed to check for updates:', err);
            }
        });
    }

    /**
     * Listen to router events and change the title depending on the route.
     */
    initVersionUpdate() {
        this.swUpdate.versionUpdates.pipe(
            filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
            untilComponentDestroyed(this)
        ).subscribe(
            () => {
                // console.log('New version marked');
                this.isNewVersionAvailable = true;
            }
        );

        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            untilComponentDestroyed(this)
        ).subscribe(
            () => {
                if (this.isNewVersionAvailable) {
                    // console.log('New version: reload--');
                    this.isNewVersionAvailable = false;
                    window.location.reload();
                }
            }
        );
    }
}
