import {Exercise} from './exercise';
import {Chapter} from './chapter';

export class Lesson {
    constructor(
        public id: number,
        public title: string,
        public icon: string,
        public chapterId: number,
        public courseId: number,
        public position: number,
        public isExam: boolean,
        public licensedOnly: boolean,
        public exercises?: Exercise[],
        public chapter?: Chapter,
        public exercisesCount?: number,
        public globalPosition?: number
    ) {
    }
}
