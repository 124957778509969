import {Injectable, Injector} from '@angular/core';
import {UserStats} from '../../../models/userStats';
import {UserFactory} from './user.factory';

@Injectable()
export class UserStatsFactory {
    constructor(
        private injector: Injector
    ) {}

    map(response) {
        const userFactory = this.injector.get(UserFactory);

        return new UserStats(
            userFactory.map(response),
            response.chapter_progress ?
                response.chapter_progress.map(
                    (chapterProgress) => {
                        return {
                            id: chapterProgress.id,
                            title: chapterProgress.title,
                            progress: chapterProgress.progress,
                            lessonProgress: chapterProgress.lesson_progress.map(
                                (lessonProgress) => {
                                    return {
                                        title: lessonProgress.title,
                                        progress: lessonProgress.progress
                                    };
                                }
                            )
                        };
                    }
                )
                : undefined
            // response.stars,
            // response.total_stars,
            // response.cpm,
            // response.correct_ratio,
            // new BehaviorSubject(0)
        );
    }
}
