import {Injectable, Injector} from '@angular/core';
import {OrganizationSettings} from '../../../models/organizationSettings';

@Injectable()
export class OrganizationSettingsFactory {
    constructor(
        private injector: Injector
    ) {}

    map(response) {
        return new OrganizationSettings(
            response.organization_id,
            response.course_id,
            response.language_id,
            !!response.allow_update_account_details,
            !!response.allow_update_password,
            !!response.allow_update_course,
            !!response.show_keyboard,
            !!response.allow_backspace,
            !!response.apple_keyboard
        );
    }
}
