import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {DiscountCode} from '../../models/discountCode';
import {environment} from '../../../environments/environment';
import {AppService} from './app.service';
import {DiscountCodeFactory} from './factories/discountCode.factory';

@Injectable()
export class DiscountCodeService {
    constructor(
        private http: HttpClient,
        private appService: AppService,
        private discountCodeFactory: DiscountCodeFactory
    ) {}

    checkCode(code, email, currencyId, orderId): Observable<DiscountCode> {
        return this.http.post<any>(environment.apiUrl + 'orders/check_discount_code', {
            code,
            email,
            currency_id: currencyId,
            order_id: orderId
        }).pipe(
            map(
                (response) => {
                    if (response) {
                        return this.discountCodeFactory.map(response);
                    }

                    return null;
                }
            ),
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    getAll(
        page: number,
        pageSize: number,
        sortBy: string,
        sortDirection: string,
        filter: string
    ) {
        if (!filter) {
            filter = '';
        }

        return this.http.get<any>(
            environment.apiUrl + 'discount_codes/all'
            + '?page=' + page
            + '&page-size=' + pageSize
            + '&sort-by=' + sortBy
            + '&sort-direction=' + sortDirection
            + '&filter=' + filter
        );
    }

    getOne(id: number | string) {
        return this.http.get<DiscountCode>(environment.apiUrl + 'discount_codes/' + id)
            .pipe(
                map(
                    (response) => {
                        return this.discountCodeFactory.map(response);
                    }
                )
            );
    }

    create(
        code: string,
        productId: number,
        currencyId: number,
        amount: number,
        maximumUses: number,
        validUntil: string,
        email: string,
        userId: number
    ) {
        return this.http.post<any>(environment.apiUrl + 'discount_codes/create', {
            code,
            product_id: productId,
            currency_id: currencyId,
            amount,
            maximum_uses: maximumUses,
            valid_until: validUntil,
            email,
            user_id: userId
        }).pipe(
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    update(
        id: number,
        code: string,
        productId: number,
        currencyId: number,
        amount: number,
        maximumUses: number,
        validUntil: string,
        email: string,
        userId: number
    ) {
        return this.http.patch<any>(environment.apiUrl + 'discount_codes/' + id, {
            code,
            product_id: productId,
            currency_id: currencyId,
            amount,
            maximum_uses: maximumUses,
            valid_until: validUntil,
            email,
            user_id: userId
        }).pipe(
            map(
                (response) => {
                    return this.discountCodeFactory.map(response);
                }
            ),
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    delete(id: number) {
        return this.http.delete(environment.apiUrl + 'discount_codes/' + id);
    }

    checkAvailable(discountCode: DiscountCode) {
        if (discountCode.validUntil <= Date.now()) {
            return false;
        }

        if (discountCode.claimsCount) {
            if (discountCode.claimsCount >= discountCode.maximumUses) {
                return false;
            }
        } else if (discountCode.orders) {
            if (discountCode.orders.length >= discountCode.maximumUses) {
                return false;
            }
        }

        return true;
    }
}
