import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, first, map, switchMap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {LessonFactory} from './factories/lesson.factory';
import {Lesson} from '../../models/lesson';
import {StateService} from '../../services/state.service';
import {AppService} from './app.service';

@Injectable()
export class LessonService {
    constructor(
        private http: HttpClient,
        private lessonFactory: LessonFactory,
        private stateService: StateService,
        private appService: AppService
    ) {}

    getOne(id: number): Observable<Lesson> {
        return this.stateService.personalSettings.getSetting('disableGames').pipe(
            first(),
            switchMap(
                (disableGames) => {
                    let url = environment.apiUrl + 'lessons/' + id;

                    if (disableGames) {
                        url += '/' + true;
                    }

                    return this.http.get<Lesson>(url)
                        .pipe(
                            map(
                                (response) => {
                                    return this.lessonFactory.map(response);
                                }
                            )
                        );
                }
            )
        );
    }

    getExam(): Observable<Lesson> {
        return this.http.get<Lesson>(environment.apiUrl + 'lessons/exam')
            .pipe(
                map(
                    (response) => {
                        return this.lessonFactory.map(response);
                    }
                )
            );
    }

    getChapterLessons(
        chapterId: number,
        page: number,
        pageSize: number,
        sortBy: string,
        sortDirection: string,
        filter: string
    ) {
        let url = environment.apiUrl + 'lessons/by_chapter_sorted/' + chapterId;

        if (!filter) {
            filter = '';
        }

        url += '?page=' + page
            + '&page-size=' + pageSize
            + '&sort-by=' + sortBy
            + '&sort-direction=' + sortDirection
            + '&filter=' + filter;

        return this.http.get<any>(
            url
        );
    }

    create(
        chapterId: number,
        afterLessonId: number,
        title: string,
        icon: string
    ) {
        return this.http.post<any>(environment.apiUrl + 'lessons/create', {
            chapter_id: chapterId,
            after_lesson_id: afterLessonId,
            title,
            icon
        }).pipe(
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    update(
        id: number,
        chapterId: number,
        afterLessonId: number,
        title: string,
        icon: string
    ) {
        return this.http.patch<any>(environment.apiUrl + 'lessons/' + id, {
            chapter_id: chapterId,
            after_lesson_id: afterLessonId,
            title,
            icon
        }).pipe(
            map(
                (response) => {
                    return this.lessonFactory.map(response);
                }
            ),
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    delete(id: number) {
        return this.http.delete(environment.apiUrl + 'lessons/' + id);
    }
}
