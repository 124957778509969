<course-auth-form-layout>
    <div class="formArea">
        <h1 i18n="@@login.title">Log in</h1>

        <div *ngIf="hasLoginError" class="dialog error">
            <h2 i18n="@@login.invalidLogin">Invalid credentials!</h2>
        </div>
        <form [formGroup]="form" (submit)="submit()">
            <mat-form-field appearance="outline">
                <mat-label i18n="@@login.emailOrUsername">E-mail or username</mat-label>
                <input type="text" matInput required tabindex="1" formControlName="email">
                <mat-error *ngIf="errors?.email">
                    <ng-container *ngFor="let err of errors.email">
                        {{ FormErrors[err] }} <br>
                    </ng-container>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="passwordField">
                <mat-label i18n="@@password">Password</mat-label>
                <input matInput required [type]="hidePassword ? 'password' : 'text'" tabindex="2" formControlName="password">
                <button
                    type="button"
                    mat-icon-button
                    matSuffix
                    (click)="hidePassword = !hidePassword"
                    [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hidePassword"
                >
                    <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
                </button>
                <mat-hint>
                    <a
                        routerLink="/forgot-password"
                        i18n="@@forgotPassword.forgotYourPassword"
                    >Forgot your password?</a>
                </mat-hint>
                <mat-error *ngIf="errors?.password">
                    <ng-container *ngFor="let err of errors.password">
                        {{ FormErrors[err] }}
                        <a
                            *ngIf="err === 'required'"
                            routerLink="/forgot-password"
                            i18n="@@forgotPassword.forgotYourPassword"
                        >Forgot your password?</a>
                        <br>
                    </ng-container>
                </mat-error>
            </mat-form-field>

            <p class="alignRight">
                <button
                    type="submit"
                    tabindex="3"
                    class="keyboardButton orangeKeyboardButton"
                >
                    <span i18n="@@login.login">Log in</span>
                </button>
            </p>
        </form>

<!--        <p class="orLogInWith">-->
<!--            <span i18n="@@orLogInWith">Or log in with</span>-->
<!--        </p>-->

<!--        <div class="ssoButtons">-->
<!--            <a href="{{ environment.apiUrl }}auth/leer-id/authenticate" class="leerID">-->
<!--                <img src="assets/images/sso/leerID.svg" alt="LeerID" /> LeerID-->
<!--            </a>-->

<!--&lt;!&ndash;            <div class="googleLoginBtn">&ndash;&gt;-->
<!--&lt;!&ndash;                <div id="g_id_onload"&ndash;&gt;-->
<!--&lt;!&ndash;                     data-client_id="191934786882-ci34cvkdbkjm85tj1rvsbco3qup0g4b6.apps.googleusercontent.com"&ndash;&gt;-->
<!--&lt;!&ndash;                     data-context="signin"&ndash;&gt;-->
<!--&lt;!&ndash;                     data-ux_mode="redirect"&ndash;&gt;-->
<!--&lt;!&ndash;                     data-login_uri="https://api.tyyp.io/api/auth/google/callback"&ndash;&gt;-->
<!--&lt;!&ndash;                     data-auto_prompt="false">&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->

<!--&lt;!&ndash;                <div class="g_id_signin"&ndash;&gt;-->
<!--&lt;!&ndash;                     data-type="standard"&ndash;&gt;-->
<!--&lt;!&ndash;                     data-shape="pill"&ndash;&gt;-->
<!--&lt;!&ndash;                     data-theme="filled_blue"&ndash;&gt;-->
<!--&lt;!&ndash;                     data-text="signin_with"&ndash;&gt;-->
<!--&lt;!&ndash;                     data-size="medium"&ndash;&gt;-->
<!--&lt;!&ndash;                     data-logo_alignment="left">&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--        </div>-->
    </div>
    <div class="registerArea">
        <p i18n="@@login.noAccountYet">Not signed up yet?</p>
        <p>
            <a
                [routerLink]="'/' + navigationService.getRouteTranslation('purchase')"
                mat-stroked-button
                color="primary"
                class="purchaseButton roundedButton"
                i18n="@@login.purchaseButton"
            >Purchase Tyyp!</a>
        </p>
    </div>
</course-auth-form-layout>
