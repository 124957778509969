import {AfterViewChecked, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Currency} from '../../../../models/currency';
import {Product} from '../../../../models/product';
import {LanguageService} from '../../../../shared/services/language.service';
import {NavigationService} from '../../../../shared/services/navigation.service';
import {StateService} from '../../../../services/state.service';
import {first} from 'rxjs/operators';
import {ProductService} from '../../../../shared/services/product.service';
import {AppService} from '../../../../shared/services/app.service';

@Component({
    selector: 'course-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit, AfterViewChecked {
    products: {
        'individual': Product,
        'school': Product,
        'business': Product
    };
    currency: Currency;

    constructor(
        private activatedRoute: ActivatedRoute,
        private stateService: StateService,
        private router: Router,
        public languageService: LanguageService,
        public navigationService: NavigationService,
        private productService: ProductService,
        private appService: AppService
    ) {
    }

    ngOnInit() {
        this.products = this.activatedRoute.snapshot.data.products;
        this.currency = this.stateService.getActiveCurrency();

        if (!this.products) {
            this.loadProducts();
        }
    }

    ngAfterViewChecked() {
        this.appService.initAnimations('course-products .slideUp', 0.2);
    }

    loadProducts() {
        this.productService.getAllTypes().pipe(
            first()
        ).subscribe(
            (productTypes: {'individual': Product, 'school': Product, 'business': Product}) => {
                this.products = productTypes;
            }
        );
    }

    addToCart() {
        let quantity = this.stateService.getCartItems();
        if (!quantity) {
            quantity = 0;
        }

        quantity++;

        this.stateService.setCartItems(quantity);

        this.router.navigate(['/checkout']);
    }
}

