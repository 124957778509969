import {Injectable, Injector} from '@angular/core';
import {Organization} from '../../../models/organization';
import {OrganizationSettingsFactory} from './organizationSettings.factory';

@Injectable()
export class OrganizationFactory {
    constructor(
        private injector: Injector
    ) {}

    map(response) {
        const organizationSettingsFactory = this.injector.get(OrganizationSettingsFactory);

        return new Organization(
            response.id,
            response.name,
            response.school,
            response.tax_number,
            response.address,
            response.city,
            response.state,
            response.postal_code,
            response.country_code,
            response.billing_email,
            response.billing_name,
            response.billing_tax_number,
            response.billing_address,
            response.billing_city,
            response.billing_state,
            response.billing_postal_code,
            response.billing_country_code,
            Date.parse(response.created_at),
            response.students_count ? response.students_count : undefined,
            response.instructors_count ? response.instructors_count : undefined,
            response.licenses_count ? response.licenses_count : undefined,
            response.organization_setting ? organizationSettingsFactory.map(response.organization_setting) : undefined,
            response.organization_type_id ? response.organization_type_id : undefined
        );
    }
}
