import {AfterViewChecked, Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Product} from '../../../models/product';
import {Currency} from '../../../models/currency';
import {ModalService} from '../../services/modal.service';
import {ProductService} from '../../services/product.service';
import {LanguageService} from '../../services/language.service';
import {StateService} from '../../../services/state.service';
import {AppService} from '../../services/app.service';

@Component({
    selector: 'course-purchase-individual',
    templateUrl: './individual.component.html',
    styleUrls: ['./individual.component.scss'],
})
export class IndividualComponent implements OnInit, AfterViewChecked {
    @Input() endOfTrial = false;
    @Input() trialCTA = false;
    @Input() products: {individual: Product, school: Product, business: Product};
    product: Product;
    currency: Currency;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        public stateService: StateService,
        private modalService: ModalService,
        private productService: ProductService,
        public languageService: LanguageService,
        private appService: AppService
    ) {}

    ngOnInit() {
        this.currency = this.stateService.getActiveCurrency();
    }

    ngOnChanges() {
        this.determineProduct();
    }

    determineProduct() {
        if (!this.products) {
            return;
        }

        const user = this.stateService.getActiveUser();

        if (!user || !user.organization) {
            this.product = this.products.individual;
        } else if (user.organization.isSchool) {
            this.product = this.products.school;
        } else {
            this.product = this.products.business;
        }
    }

    ngAfterViewChecked() {
        this.initAnimations();
    }

    initAnimations() {
        if (this.endOfTrial || this.trialCTA) {
            // trialCTA & endOfTrial screens have their own animation, so we add a little delay
            setTimeout(
                () => {
                    this.appService.initAnimations('course-purchase-individual .slideLeft, course-purchase-individual .slideRight', 0.2);
                }, 1200
            );
        } else {
            this.appService.initAnimations('course-purchase-individual .slideLeft, course-purchase-individual .slideRight', 0.2);
        }
    }

    addToCart() {
        if (this.trialCTA) {
            this.modalService.closeModal();
        }

        let quantity = this.stateService.getCartItems();
        if (!quantity) {
            quantity = 0;
        }

        quantity++;

        this.stateService.setCartItems(quantity);

        this.router.navigate(['/checkout']);
    }
}

