import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {NEVER, Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ProductService} from '../../shared/services/product.service';

@Injectable()
export class MyProductResolver  {
    constructor(
        private productService: ProductService
    ) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any>|Promise<any>|any {
        return this.productService.getMine().pipe(
            catchError(
                (err) => {
                    return NEVER;
                }
            )
        );
    }
}
