import {KeyboardZones} from './enums/keyboardZones';
import {Course} from './course';
import {Lesson} from './lesson';
import {Exercise} from './exercise';

export class Chapter {
    constructor(
        public id: number,
        public courseId: number,
        public title: string,
        public lessons: Lesson[],
        public zones: KeyboardZones[],
        public isExam: boolean,
        public theme: string,
        public position: number,
        public course?: Course,
        public organizationExercises?: Exercise[],
        public keyboardLayoutName?: string
    ) {}
}
