import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Product} from '../../../../models/product';
import {Currency} from '../../../../models/currency';
import {Country} from '../../../../models/country';
import {catchError, first} from 'rxjs/operators';
import {NEVER} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {User} from '../../../../models/user';
import {DiscountCode} from '../../../../models/discountCode';
import {Products} from '../../../../models/abstract/products';
import {OrderService} from '../../../../shared/services/order.service';
import {UserService} from '../../../../shared/services/user.service';
import {DiscountCodeService} from '../../../../shared/services/discountCode.service';
import {CourseService} from '../../../../shared/services/course.service';
import {LanguageService} from '../../../../shared/services/language.service';
import {CountryService} from '../../../../shared/services/country.service';
import {AppService} from '../../../../shared/services/app.service';
import {ProductService} from '../../../../shared/services/product.service';
import {StateService} from '../../../../services/state.service';
import {AnalyticsService} from '../../../../services/analytics.service';

@Component({
    selector: 'course-purchase',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnInit {
    protected readonly Products = Products;
    protected readonly DiscountCodeErrors = {
        invalidCode: $localize`:@@purchase.discountCodeErrors.invalidCode:Invalid code`,
        expiredCode: $localize`:@@purchase.discountCodeErrors.expiredCode:Expired code`,
        usedUp: $localize`:@@purchase.discountCodeErrors.usedUp:This code has already been used`,
        linkedToOtherEmail: $localize`:@@purchase.discountCodeErrors.linkedToOtherEmail:This code is linked to another e-mail address`,
        linkedToOtherUser: $localize`:@@purchase.discountCodeErrors.linkedToOtherUser:Log in to use this code`,
    };
    @ViewChild('quantityInput') quantityInput;
    maxLogins: number; // max logins allowed (payment limitations!)
    quantities = ['0', '1', '2', '3', '4', '5+'];
    quantity = 1;
    product: Product;
    currency: Currency;
    errors: { [field: string]: string[] } = {};
    countries: Country[];
    activeUser: User;
    currentDiscountCode: DiscountCode;
    orderId: number; // used to update an existing order (abandoned cart)
    courseId: number;

    form: FormGroup<{
        firstName: FormControl<string | null>;
        name: FormControl<string | null>;
        email: FormControl<string | null>;
        companyName: FormControl<string | null>;
        taxNumber: FormControl<string | null>;
        countryCode: FormControl<string | null>;
        address: FormControl<string | null>;
        postalCode: FormControl<string | null>;
        state: FormControl<string | null>;
        city: FormControl<string | null>;
        acceptTerms: FormControl<boolean | null>;
    }>;

    discountForm = new FormGroup({
        discountCode: new FormControl<string | null>('')
    });

    quantityForm = new FormGroup({
        quantitySelect: new FormControl<string | null>('1'),
        quantityInput: new FormControl<string | null>('1')
    });


    constructor(
        private activatedRoute: ActivatedRoute,
        private stateService: StateService,
        public orderService: OrderService,
        private userService: UserService,
        private discountCodeService: DiscountCodeService,
        private courseService: CourseService,
        private languageService: LanguageService,
        public countryService: CountryService,
        private appService: AppService,
        private router: Router,
        private productService: ProductService,
        private analyticsService: AnalyticsService
    ) {
    }

    ngOnInit() {
        this.product = this.activatedRoute.snapshot.data.product;
        this.countries = this.activatedRoute.snapshot.data.countries;

        this.activeUser = this.stateService.getActiveUser();

        this.currency = this.stateService.getActiveCurrency();

        // default country
        let defaultCountryCode = this.appService.getDomainDefaults().countryCode;

        // country is in the list?
        if (this.countries.filter(country => country.code === defaultCountryCode).length < 1) {
            defaultCountryCode = null;
        }

        this.form = new FormGroup({
            firstName: new FormControl<string | null>(''),
            name: new FormControl<string | null>(''),
            email: new FormControl<string | null>(''),
            companyName: new FormControl<string | null>(''),
            taxNumber: new FormControl<string | null>(''),
            countryCode: new FormControl<string | null>(defaultCountryCode),
            address: new FormControl<string | null>(''),
            postalCode: new FormControl<string | null>(''),
            state: new FormControl<string | null>(''),
            city: new FormControl<string | null>(''),
            acceptTerms: new FormControl<boolean | null>(false)
        });

        this.maxLogins = this.appService.maxLicensesOnlinePayment.guest;

        this.setInitialQuantity();
        this.populateFromPreviousOrder();
    }

    setInitialQuantity() {
        this.setQuantity(
            this.stateService.getCartItems()
        );
    }

    setQuantity(quantity: number) {
        this.quantity = quantity;

        if (this.quantity > this.maxLogins) {
            this.quantity = this.maxLogins;
        }

        if (isNaN(this.quantity) || this.quantity < 1) {
            this.quantity = 1;
        }

        if (this.quantity > 4) {
            this.quantityForm.get('quantitySelect').setValue('5+');
            this.quantityForm.get('quantityInput').setValue('' + this.quantity);
        } else {
            this.quantityForm.get('quantitySelect').setValue('' + this.quantity);
        }
    }

    getFullPrice() {
        return (Math.round(this.product.fullPriceIncl * this.quantity * 100) / 100);
    }

    getListPrice() {
        return (Math.round(this.product.calculatedIncl * this.quantity * 100) / 100);
    }

    /**
     * Populate the form:
     * - First check if we are accessing a previous cart (link through abandoned cart email)
     * - Else check if the user is logged in:
     *      - If the user has a previous order, we can use that info (but not the quantity & discount code)
     *      - If not, at least we can use the user's account info (though limited)
     */
    populateFromPreviousOrder() {
        let orderData = this.activatedRoute.snapshot.data.restoreOrder;

        if (orderData && !orderData.paidAt) {
            // store orderId
            this.orderId = orderData.id;

            // quantity (only one product for now)
            const quantity = orderData.products[0].amount;
            this.setQuantity(quantity);

            // update localstorage quantity
            this.updateQuantity(quantity);

            // restore discount code
            if (orderData.discountCode) {
                this.discountForm.get('discountCode').setValue(orderData.discountCode.code);
                this.currentDiscountCode = orderData.discountCode;
            }
        } else {
            orderData = this.activatedRoute.snapshot.data.previousOrder;
            const user = this.stateService.getActiveUser();

            if (!orderData && user) {
                orderData = {
                    firstName: user.firstName,
                    name: user.name,
                    email: user.email
                };
            }
        }

        if (!orderData) {
            return;
        }

        this.form.get('firstName').setValue(orderData.firstName);
        this.form.get('name').setValue(orderData.name);
        this.form.get('email').setValue(orderData.email);
        this.form.get('companyName').setValue(orderData.companyName);
        this.form.get('taxNumber').setValue(orderData.taxNumber);
        this.form.get('countryCode').setValue(orderData.countryCode);
        this.form.get('address').setValue(orderData.address);
        this.form.get('postalCode').setValue(orderData.postalCode);
        this.form.get('state').setValue(orderData.state);
        this.form.get('city').setValue(orderData.city);
    }

    selectQuantity(evt) {
        if (evt.value === '5+') {
            this.updateQuantity(5);
            this.quantityForm.get('quantityInput').setValue('' + this.quantity);

            // element still hidden -> cant receive focus, so a little timeout
            window.setTimeout(
                () => {
                    this.quantityInput.nativeElement.focus();
                }, 1
            );
        } else {
            if (evt.value < 1) {
                this.quantityForm.get('quantitySelect').setErrors(['minimum 1']);
            }

            this.updateQuantity(evt.value);
        }
    }

    changeQuantityInput(value) {
        // strip non-numerical
        if (isNaN(value)) {
            value = value.replace(/\D/g, '');
        }

        if (value > this.maxLogins) {
            value = this.maxLogins;
        }

        this.updateQuantity(value);

        this.quantityForm.get('quantityInput').setValue('' + this.quantity);
    }

    updateQuantity(quantity: number) {
        this.quantity = quantity;
        this.stateService.setCartItems(this.quantity);
    }

    submitPurchase() {
        // get either the active course or the default course for the current domain to link to the order
        this.courseId = this.stateService.getActiveCourseId();

        if (this.courseId) {
            this.createOrder();
        } else {
            this.courseService.getDefaultCourse().pipe(
                first()
            ).subscribe(
                (courseId) => {
                    this.courseId = courseId;
                    this.createOrder();
                }
            );
        }

        return false;
    }

    createOrder() {
        this.orderService.create(
            this.form.controls.firstName.value,
            this.form.controls.name.value,
            this.form.controls.email.value,
            this.form.controls.companyName.value,
            this.form.controls.taxNumber.value,
            this.form.controls.address.value,
            this.form.controls.postalCode.value,
            this.form.controls.city.value,
            this.form.controls.state.value,
            this.form.controls.countryCode.value,
            [
                {
                    id: this.product.id,
                    amount: this.quantity
                }
            ],
            this.currency.code,
            this.currentDiscountCode ? this.currentDiscountCode.code : '',
            this.orderId,
            this.courseId,
            this.languageService.getActiveLanguage().id,
            this.form.controls.acceptTerms.value
        ).pipe(
            first(),
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.errors = err.error.errors;

                        if (err.error.errors) {
                            this.form.get('firstName').setErrors(err.error.errors.first_name);
                            this.form.get('name').setErrors(err.error.errors.name);
                            this.form.get('email').setErrors(err.error.errors.email);
                            this.form.get('companyName').setErrors(err.error.errors.company_name);
                            this.form.get('taxNumber').setErrors(err.error.errors.tax_number);
                            this.form.get('address').setErrors(err.error.errors.address);
                            this.form.get('postalCode').setErrors(err.error.errors.postal_code);
                            this.form.get('city').setErrors(err.error.errors.city);
                            this.form.get('state').setErrors(err.error.errors.state);
                            this.form.get('countryCode').setErrors(err.error.errors.country_code);
                            this.form.get('acceptTerms').setErrors(err.error.errors.accept_terms);

                            this.discountForm.get('discountCode').setErrors(err.error.errors.discount_code);
                        }
                    }

                    return NEVER;
                }
            )
        ).subscribe(
            (url) => {
                this.errors = {};

                this.stateService.setCartItems(0);

                this.analyticsService.trackConversion('individualCheckout');

                if (url === 'noPaymentRequired') {
                    // no payment required (free)
                    this.router.navigate(['/payment-completed']);
                } else {
                    // redirect to payment
                    window.location.href = url;
                }
            }
        );
    }

    submitDiscountCode() {
        const code = this.discountForm.get('discountCode').value;

        this.discountCodeService.checkCode(code, this.form.get('email').value, this.currency.id, this.orderId).pipe(
            first(),
            catchError(
                (err) => {
                    if (err.status === 422) {
                        const errorMessage = err.error;
                        this.errors.discount_code = [errorMessage];
                        this.discountForm.get('discountCode').setErrors(err);
                    }

                    return NEVER;
                }
            )
        ).subscribe(
            (discountCode) => {
                this.errors = {};
                this.discountForm.get('discountCode').setErrors(null);

                this.currentDiscountCode = discountCode;
            }
        );

        return false;
    }

    clearDiscountCode() {
        this.currentDiscountCode = undefined;
        this.discountForm.reset();
    }

    getTotal() {
        let total = this.getListPrice();

        if (this.currentDiscountCode) {
            total -= this.currentDiscountCode.amount;
        }

        if (total < 0) {
            total = 0;
        }

        return (Math.round(total * 100) / 100).toFixed(2);
    }

    taxNumberChanges() {
        this.companyChanges();
        this.reloadProduct();
    }

    countryChanges() {
        this.reloadProduct();
    }

    reloadProduct() {
        this.productService.getMine(
            this.form.get('countryCode').value,
            !!this.form.get('taxNumber').value
        ).pipe(
            first()
        ).subscribe(
            (product: Product) => {
                this.product = product;
            }
        );
    }

    companyChanges() {
        const companyName = this.form.get('companyName').value;
        const taxNumber = this.form.get('taxNumber').value;

        if (companyName === '') {
            // clear tax number errors
            this.errors.tax_number = [];
            this.form.get('taxNumber').setErrors(null);
        }

        if (taxNumber === '') {
            // clear company name errors
            this.errors.company_name = [];
            this.form.get('companyName').setErrors(null);
        }
    }
}

