import {Injectable, Injector} from '@angular/core';
import {Chapter} from '../../../models/chapter';
import {KeyboardZones} from '../../../models/enums/keyboardZones';
import {CourseFactory} from './course.factory';
import {LessonFactory} from './lesson.factory';
import {ExerciseFactory} from './exercise.factory';

@Injectable()
export class ChapterFactory {
    constructor(
        private injector: Injector
    ) {}

    map(response) {
        const lessonFactory = this.injector.get(LessonFactory);
        const courseFactory = this.injector.get(CourseFactory);
        const exerciseFactory = this.injector.get(ExerciseFactory);

        // keyboard zones
        const zones = [];
        if (response.keyboard_home) {
            zones.push(KeyboardZones.Home);
        }
        if (response.keyboard_top) {
            zones.push(KeyboardZones.Top);
        }
        if (response.keyboard_bottom) {
            zones.push(KeyboardZones.Bottom);
        }
        if (response.keyboard_full) {
            zones.push(KeyboardZones.Full);
        }

        // chapter
        return new Chapter(
            response.id,
            response.course_id,
            response.title,
            response.lessons ? response.lessons.map(lesson => lessonFactory.map(lesson)) : [],
            zones,
            response.exam,
            response.theme,
            response.position,
            response.course ? courseFactory.map(response.course) : undefined,
            response.organization_exercises ?
                response.organization_exercises.map(exercise => exerciseFactory.map(exercise)) : undefined,
            response.course && response.course.keyboard_layout ?
                response.course.keyboard_layout.code
                : undefined,
        );
    }
}
