import {Injectable} from '@angular/core';
import {catchError, map, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {AppService} from './app.service';
import {OrderFactory} from './factories/order.factory';
import {CurrencyService} from './currency.service';
import {ClassFactory} from './factories/class.factory';
import {ImportBatchFactory} from './factories/importBatch.factory';
import {UserFactory} from './factories/user.factory';
import {DownloadService} from './download.service';
import {environment} from '../../../environments/environment';
import {StateService} from '../../services/state.service';
import {ExternalProduct} from '../../models/externalProduct';

@Injectable()
export class OrderService {
    constructor(
        private http: HttpClient,
        private appService: AppService,
        private orderFactory: OrderFactory,
        private currencyService: CurrencyService,
        private classFactory: ClassFactory,
        private importBatchFactory: ImportBatchFactory,
        private userFactory: UserFactory,
        private downloadService: DownloadService,
        private stateService: StateService
    ) {
    }

    restoreCartItems(): void {
        const num = parseInt(localStorage.getItem('shop.items'), 10);
        this.stateService.setCartItems(num);
    }

    getOne(id) {
        return this.http.get<any>(environment.apiUrl + 'orders/' + id).pipe(
            map(
                (response) => {
                    return this.orderFactory.map(response);
                }
            )
        );
    }

    getPaymentStatus(id: number, code: string) {
        return this.http.get<any>(environment.apiUrl + 'orders/payment_status/' + id + '/' + code);
    }

    retryPayment(id: number, code: string) {
        return this.http.post<any>(environment.apiUrl + 'orders/retry_payment', {
            id,
            code
        }).pipe(
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    create(
        firstName: string,
        name: string,
        email: string,
        companyName: string,
        taxNumber: string,
        address: string,
        postalCode: string,
        city: string,
        state: string,
        countryCode: string,
        products: [{ id: number, amount: number }],
        currencyCode: string,
        discountCode: string,
        orderId: number,
        courseId: number,
        languageId: number,
        acceptTerms: boolean
    ) {
        return this.http.post<any>(environment.apiUrl + 'orders/create', {
            first_name: firstName,
            name,
            email,
            company_name: companyName,
            tax_number: taxNumber,
            address,
            postal_code: postalCode,
            city,
            state,
            country_code: countryCode,
            products: JSON.stringify(products),
            currency_code: currencyCode,
            discount_code: discountCode,
            order_id: orderId,
            course_id: courseId,
            language_id: languageId,
            accept_terms: acceptTerms
        }).pipe(
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    getOrder(id, code) {
        return this.http.get<any>(environment.apiUrl + 'orders/' + id + '/' + code)
            .pipe(
                map(
                    (response) => {
                        if (response) {
                            console.log(this.orderFactory.map(response));
                            return this.orderFactory.map(response);
                        }

                        return null;
                    }
                )
            );
    }

    downloadInvoice(id) {
        return this.downloadService.downloadFile('orders/invoice/' + id);
    }

    generatePaymentLink(id, fromImport = true) {
        return this.http.get<any>(environment.apiUrl + 'orders/generate_payment_link/' + id + '/' + (fromImport ? 1 : 0));
    }

    getOrganizationPaymentStatus(id: number) {
        return this.http.get<any>(environment.apiUrl + 'orders/organization_payment_status/' + id);
    }

    getLicenseOrder(id) {
        return this.http.get<any>(environment.apiUrl + 'orders/get_license_order/' + id)
            .pipe(
                map(
                    (response) => {
                        const order = this.orderFactory.map(response);
                        const importBatch = this.importBatchFactory.map(response.import_batch);
                        const group = this.classFactory.map(response.import_batch.group);
                        const users = response.import_batch.users.map(
                            (user) => {
                                return this.userFactory.map(user);
                            }
                        );

                        return {
                            order,
                            importBatch,
                            class: group,
                            users
                        };
                    }
                )
            );
    }

    createForImportBatch(importBatchId: number) {
        return this.http.post<any>(environment.apiUrl + 'orders/create_for_import_batch', {
            import_batch_id: importBatchId
        }).pipe(
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    createForAmount(amount: number, organizationId?: number, poNumber?: string) {
        return this.http.post<any>(environment.apiUrl + 'orders/create_for_amount', {
            amount,
            organization_id: organizationId,
            po_number: poNumber
        }).pipe(
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    createExternal(organizationId: number, externalProducts: ExternalProduct[]) {
        return this.http.post<any>(environment.apiUrl + 'orders/create_external', {
            organization_id: organizationId,
            external_products: externalProducts.map((product) => {
                return {
                    description: product.description,
                    amount: product.amount,
                    unit_price: product.unitPrice
                };
            })
        }).pipe(
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    getLastOrder() {
        return this.http.get<any>(environment.apiUrl + 'orders/last')
            .pipe(
                map(
                    (response) => {
                        if (response) {
                            return this.orderFactory.map(response);
                        }

                        return null;
                    }
                )
            );
    }

    getForOrganization(
        id: number | string,
        page: number,
        pageSize: number,
        sortBy: string,
        sortDirection: string,
        filter: string
    ) {
        if (!filter) {
            filter = '';
        }

        return this.http.get<any>(
            environment.apiUrl + 'orders/for_organization/' + id
            + '?page=' + page
            + '&page-size=' + pageSize
            + '&sort-by=' + sortBy
            + '&sort-direction=' + sortDirection
            + '&filter=' + filter
        );
    }

    getUnpaidInvoiceCount() {
        return this.http.get<any>(environment.apiUrl + 'orders/count_unpaid').pipe(
            tap(
                (numUnpaid) => {
                    const notifications = this.stateService.getNotifications();
                    notifications.unpaidInvoices = numUnpaid;
                    this.stateService.setNotifications(notifications);
                }
            )
        );
    }

    cancelOrder(id) {
        return this.http.patch<any>(environment.apiUrl + 'orders/cancel', {
            id
        });
    }

    refundOrder(id) {
        return this.http.patch<any>(environment.apiUrl + 'orders/refund', {
            id
        });
    }

    forceFullRefundOrder(id) {
        return this.http.patch<any>(environment.apiUrl + 'orders/force_full_refund', {
            id
        });
    }

    markPaid(id) {
        return this.http.patch<any>(environment.apiUrl + 'orders/mark_paid', {
            id
        });
    }

    paymentCancelled(id) {
        return this.http.patch<any>(environment.apiUrl + 'orders/payment_cancelled', {
            id
        });
    }

    calculateTotalsForAmount(organizationId, amount) {
        return this.http.get<any>(
            environment.apiUrl + 'orders/calculate_totals_for_amount/' + organizationId + '/' + amount
        );
    }
}
