import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {first, map, switchMap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Chapter} from '../../models/chapter';
import {ChapterFactory} from './factories/chapter.factory';
import {StateService} from '../../services/state.service';

@Injectable()
export class ChapterService {
    constructor(
        private http: HttpClient,
        private chapterFactory: ChapterFactory,
        private stateService: StateService
    ) {}

    getOne(id: number): Observable<Chapter> {
        return this.stateService.personalSettings.getSetting('disableGames').pipe(
            first(),
            switchMap(
                (disableGames) => {
                    let url = environment.apiUrl + 'chapters/' + id;

                    if (disableGames) {
                        url += '/' + true;
                    }

                    return this.http.get<Chapter>(url)
                        .pipe(
                            map(
                                (response) => {
                                    return this.chapterFactory.map(response);
                                }
                            )
                        );
                }
            )
        );
    }

    getCourseChapters(
        courseId: number,
        page: number,
        pageSize: number,
        sortBy: string,
        sortDirection: string,
        filter: string
    ) {
        let url = environment.apiUrl + 'chapters/by_course_sorted/' + courseId;

        if (!filter) {
            filter = '';
        }

        url += '?page=' + page
            + '&page-size=' + pageSize
            + '&sort-by=' + sortBy
            + '&sort-direction=' + sortDirection
            + '&filter=' + filter;

        return this.http.get<any>(
            url
        );
    }
}
