import {Injectable} from '@angular/core';
import {Product} from '../../../models/product';
import {StateService} from '../../../services/state.service';

@Injectable()
export class ProductFactory {
    constructor(
        private stateService: StateService
    ) {}

    map(response) {
        const activeCurrency = this.stateService.getActiveCurrency();

        const currencyInfo = response.currencies.filter(
            (currency) => currency.id === activeCurrency.id
        )[0].pivot;

        return new Product(
            response.id,
            response.title,
            response.total_days_valid,
            currencyInfo.list_price,
            currencyInfo.tax_included,
            response.business,
            response.school,
            response.full_price_excl,
            response.full_price_incl,
            response.discount_on_full_price,
            response.calculated_excl,
            response.calculated_incl,
            response.best_active_sale ? response.best_active_sale.name : null,
            response.best_active_sale ? response.best_active_sale.valid_until : null
        );
    }
}
