export class MenuItems {
    public static readonly course = {
        label: $localize `:@@nav.course:Learn`,
        translatedRouterLink: 'course'
    };

    public static readonly tryCourse = {
        label: $localize `:@@nav.tryCourse:Try it free`,
        translatedRouterLink: 'course'
    };

    public static readonly progress = {
        label: $localize `:@@nav.progress:Progress`,
        translatedRouterLink: 'progress',
        id: 'progress'
    };

    public static readonly forSchools = {
        label: $localize `:@@nav.forSchools:For schools`,
        translatedRouterLink: 'forSchools'
    };

    public static readonly forBusiness = {
        label: $localize `:@@nav.forBusiness:For business`,
        translatedRouterLink: 'forBusinesses'
    };

    public static readonly purchase = {
        label: $localize `:@@nav.purchase:Purchase`,
        translatedRouterLink: 'purchase'
    };

    public static readonly contact = {
        label: $localize `:@@nav.contact:Contact us`,
        translatedRouterLink: 'contact'
    };

    public static readonly controlPanel = {
        label: $localize `:@@nav.controlPanel:Control panel`,
        routerLink: '/organization',
        id: 'controlPanel'
    };

    public static readonly supremeLeaderControlPanel = {
        label: $localize `:@@nav.controlPanel:Control panel`,
        routerLink: '/admin',
        id: 'controlPanel'
    };

    public static readonly dashboard = {
        label: $localize `:@@dashboard.title:Dashboard`,
        icon: 'dashboard',
        routerLink: '/organization/dashboard'
    };

    public static readonly classes = {
        label: $localize `:@@organization.classes:Classes`,
        icon: 'classes',
        routerLink: '/organization/classes'
    };

    public static readonly myClasses = {
        label: $localize `:@@organization.myClasses:My classes`,
        icon: 'classes',
        routerLink: '/organization/classes'
    };

    public static readonly students = {
        label: $localize `:@@organization.students:Students`,
        icon: 'students',
        routerLink: '/organization/students'
    };

    public static readonly admins = {
        label: $localize `:@@organization.administratorsAndTeachers:Instructors`,
        icon: 'instructors',
        routerLink: '/organization/instructors'
    };

    public static readonly myExercises = {
        label: $localize `:@@organization.myExercises:My exercises`,
        icon: 'exercises',
        routerLink: '/organization/exercises'
    };

    public static readonly licenses = {
        label: $localize `:@@organization.licenses:Licenses`,
        icon: 'licenses',
        routerLink: '/organization/licenses',
        id: 'licenses'
    };

    public static readonly billing = {
        label: $localize `:@@organization.billing:Billing`,
        icon: 'billing',
        routerLink: '/organization/billing',
        id: 'billing'
    };

    public static readonly settings = {
        label: $localize `:@@organization.settings:Settings`,
        icon: 'settings',
        routerLink: '/organization/settings'
    };

    public static readonly orgs = {
        label: $localize `:@@admin.organizations:Organizations`,
        icon: 'organizations',
        routerLink: '/admin/organizations',
    };

    public static readonly users = {
        label: $localize `:@@admin.users:Users`,
        icon: 'users',
        routerLink: '/admin/users',
    };

    public static readonly exercises = {
        label: $localize `:@@organization.exercises:Exercises`,
        icon: 'exercises',
        routerLink: '/admin/exercises'
    };

    public static readonly discountCodes = {
        label: $localize `:@@admin.discountCodes:Discount codes`,
        icon: 'discountCodes',
        routerLink: '/admin/discount-codes'
    };

    public static readonly sales = {
        label: $localize `:@@admin.sales:Sales`,
        icon: 'sales',
        routerLink: '/admin/sales'
    };

    public static readonly stats = {
        label: $localize `:@@admin.statistics:Statistics`,
        icon: 'stats',
        routerLink: '/admin/statistics'
    };

    public static readonly billingAdmin = {
        label: $localize `:@@organization.billing:Billing`,
        icon: 'billing',
        routerLink: '/admin/billing'
    };

    public static readonly support = {
        label: $localize `:@@nav.support:Help center`,
        routerLink: '/support',
    };

    public static readonly privacy = {
        label: $localize `:@@nav.privacy:Privacy policy`,
        routerLink: '/privacy',
    };

    public static readonly terms = {
        label: $localize `:@@nav.terms:Terms of service`,
        routerLink: '/terms',
    };

    public static readonly salesOrganizations = {
        label: $localize `:@@admin.organizations:Organizations`,
        routerLink: '/sales/organizations'
    };

    public static readonly salesSales = {
        label: $localize `:@@sales:Sales`,
        routerLink: '/sales/sales'
    };
}
