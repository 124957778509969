import {Organization} from './organization';
import {Class} from './class';
import {Course} from './course';
import {Language} from './language';

export class User {
    constructor(
        public id: number,
        public firstName: string,
        public name: string,
        public email: string,
        public username: string,
        public roleId: number,
        public courseId: number,
        public appleKeyboard: boolean,
        public soundEffects: boolean,
        public gameSoundEffects: boolean,
        public gameMusic: boolean,
        public largeText: boolean,
        public highContrast: boolean,
        public closedCaptions: boolean,
        public disableGames: boolean,
        public hasCertificate: boolean,
        public languageId: number | null,
        public createdAt: number,
        public organizationId: number,
        public gameSpeed: number,
        public gameSpeedAutomatic: boolean,
        public organization?: Organization,
        public classes?: Class[],
        public longestValidLicenseUntil?: number,
        public latestLogin?: number,
        public course?: Course,
        public language?: Language,
        public deletedAt?: number,
        public deletedBy?: User,
        public optIn?: boolean,
        public classesCount?: number
    ) {}
}
