import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {StateService} from '../state.service';

@Injectable()
export class NotLoggedInGuard  {
    constructor(
        private stateService: StateService
    ) {}

    canActivate(): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
        if (!this.stateService.getActiveUser()) {
            return true;
        }
    }
}
