import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {AppService} from './app.service';
import {DownloadService} from './download.service';
import {StateService} from '../../services/state.service';

@Injectable()
export class LicenseService {
    constructor(
        private http: HttpClient,
        private stateService: StateService,
        private appService: AppService,
        private downloadService: DownloadService
    ) {
    }

    checkLicenseValid(date) {
        // check the date
        return !(!date || date < Date.now());
    }

    getInstructorAccessStats() {
        return this.http.get<any>(environment.apiUrl + 'licenses/instructor_access_stats');
    }

    countAvailable(organizationId: number) {
        return this.http.get<any>(environment.apiUrl + 'licenses/count_available/' + organizationId);
    }

    getOrganizationStats(id: number | string) {
        return this.http.get<any>(environment.apiUrl + 'licenses/organization_stats/' + id).pipe(
            tap(
                (stats) => {
                    if (id === 'mine') {
                        const notifications = this.stateService.getNotifications();
                        notifications.withoutLicense = stats.noLicense + stats.expiredLicense;
                        this.stateService.setNotifications(notifications);
                    }
                }
            )
        );
    }

    assignAvailable(importBatchId) {
        return this.http.patch<any>(environment.apiUrl + 'licenses/assign_available', {
            import_batch_id: importBatchId
        }).pipe(
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    generateQuote(organizationId, numLicenses, importBatchId) {
        let url = 'licenses/quote/' + organizationId + '/' + numLicenses;

        if (importBatchId) {
            url += '/' + importBatchId;
        }

        return this.downloadService.downloadFile(url);
    }

    assignFreeLicenses(organizationId, amount) {
        return this.http.post<any>(environment.apiUrl + 'licenses/assign_free', {
            organization_id: organizationId,
            amount
        }).pipe(
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    activateUserLicense(id: number) {
        return this.http.patch<any>(environment.apiUrl + 'licenses/activate_user_license', {
            id
        }).pipe(
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }
}
