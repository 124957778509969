import {Injectable, Injector} from '@angular/core';
import {User} from '../../../models/user';
import {ClassFactory} from './class.factory';
import {OrganizationFactory} from './organization.factory';
import {CourseFactory} from './course.factory';
import {LanguageFactory} from './language.factory';

@Injectable()
export class UserFactory {
    constructor(
        private injector: Injector
    ) {}

    map(response) {
        const userFactory = this.injector.get(UserFactory);
        const organizationFactory = this.injector.get(OrganizationFactory);
        const classFactory = this.injector.get(ClassFactory);
        const courseFactory = this.injector.get(CourseFactory);
        const languageFactory = this.injector.get(LanguageFactory);

        return new User(
            response.id,
            response.first_name,
            response.name,
            response.email,
            response.username,
            response.role_id,
            response.course_id,
            response.apple_keyboard,
            response.sound_effects,
            response.game_sound_effects,
            response.game_music,
            response.large_text,
            response.high_contrast,
            response.closed_captions,
            response.disable_games,
            response.has_certificate,
            response.language_id,
            Date.parse(response.created_at),
            response.organization_id,
            response.game_speed,
            response.game_speed_automatic,
            response.organization ? organizationFactory.map(response.organization) : undefined,
            response.groups ? response.groups.map(
                (group) => {
                    return classFactory.map(group);
                }
            ) : undefined,
            response.longest_valid_license ? Date.parse(response.longest_valid_license.valid_until) : undefined,
            response.last_login_at ? Date.parse(response.last_login_at) : undefined,
            response.course ? courseFactory.map(response.course) : undefined,
            response.language ? languageFactory.map(response.language) : undefined,
            response.deleted_at ? Date.parse(response.deleted_at) : undefined,
            response.deleted_by ? userFactory.map(response.deleted_by) : undefined,
            response.opt_in,
            response.groups_count ? response.groups_count : undefined
        );
    }
}
