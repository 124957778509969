import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter, map, mergeMap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {OrderService} from '../../../../shared/services/order.service';
import {NavigationService} from '../../../../shared/services/navigation.service';
import {StateService} from '../../../../services/state.service';

@Component({
    selector: 'course-cta-footer',
    templateUrl: './ctaFooter.component.html',
    styleUrls: ['./ctaFooter.component.scss'],
})
export class CtaFooterComponent implements OnInit {
    ctaFooter: Observable<string | undefined>;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private orderService: OrderService,
        public navigationService: NavigationService,
        private stateService: StateService
    ) {}

    ngOnInit() {
        this.checkIfHasCtaFooter();
    }

    checkIfHasCtaFooter() {
        this.ctaFooter = this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map((route) => {
                while (route.firstChild) {
                    route = route.firstChild;
                }

                return route;
            }),
            mergeMap((route) => route.data),
            map(
                (data) => {
                    return data.ctaFooter;
                }
            )
        );
    }

    addToCart() {
        let quantity = this.stateService.getCartItems();
        if (!quantity) {
            quantity = 0;
        }

        quantity++;

        this.stateService.setCartItems(quantity);

        this.router.navigate(['/checkout']);
    }
}

