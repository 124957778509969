<button class="question linkBtn" (click)="toggleAnswer()" [ngSwitch]="questionName">
    <ng-container *ngSwitchCase="'tablet'" i18n="@@website.faq.tablet">Can I also practice on my tablet/iPad?</ng-container>
    <ng-container *ngSwitchCase="'age'" i18n="@@website.faq.age">At what age can you learn to type?</ng-container>
    <ng-container *ngSwitchCase="'duration'" i18n="@@website.faq.duration">How long does the course take to complete?</ng-container>
    <ng-container *ngSwitchCase="'dyslexia'" i18n="@@website.faq.dyslexia">I have dyslexia. Can I take the course?</ng-container>
    <ng-container *ngSwitchCase="'limitation'" i18n="@@website.faq.limitation">I have a disability. Can I use the course?</ng-container>
    <ng-container *ngSwitchCase="'schoolNotYet'" i18n="@@website.faq.schoolNotYet">My school or company doesn't work with Tyyp yet. Can I still take the course?</ng-container>
    <ng-container *ngSwitchCase="'costSchool'" i18n="@@website.faq.costSchool">Are there any costs for the school itself?</ng-container>
    <ng-container *ngSwitchCase="'costBusiness'" i18n="@@website.faq.costBusiness">Can we use the tracking platform for free?</ng-container>
    <ng-container *ngSwitchCase="'freeForInstructorsSchool'" i18n="@@website.faq.freeForInstructorsSchool">Why is the course free for teachers?</ng-container>
    <ng-container *ngSwitchCase="'freeForInstructorsBusiness'" i18n="@@website.faq.freeForInstructorsBusiness">Why is the course free for instructors?</ng-container>
    <ng-container *ngSwitchCase="'whoPaysSchool'" i18n="@@website.faq.whoPaysSchool">Who pays for student licenses?</ng-container>
    <ng-container *ngSwitchCase="'whoPaysBusiness'" i18n="@@website.faq.whoPaysBusiness">Who pays for participants' licenses?</ng-container>
    <ng-container *ngSwitchCase="'accessSchool'" i18n="@@website.faq.accessSchool">How long do students have access to the course?</ng-container>
    <ng-container *ngSwitchCase="'accessBusiness'" i18n="@@website.faq.accessBusiness">How long do participants have access to the course?</ng-container>
    <ng-container *ngSwitchCase="'coachingSchool'" i18n="@@website.faq.coachingSchool">Does the school need to follow-up on the students?</ng-container>
    <ng-container *ngSwitchCase="'coachingBusiness'" i18n="@@website.faq.coachingBusiness">Does our company need to follow-up on the participants?</ng-container>
    <ng-container *ngSwitchCase="'classicalSchool'" i18n="@@website.faq.classicalSchool">Can students also practice at home?</ng-container>
    <ng-container *ngSwitchCase="'classicalBusiness'" i18n="@@website.faq.classicalBusiness">Is it possible to practice at home?</ng-container>
    <ng-container *ngSwitchCase="'accessibilitySchool'" i18n="@@website.faq.accessibilitySchool">Is the course also suitable for students with dyslexia or a disability?</ng-container>
    <ng-container *ngSwitchCase="'accessibilityBusiness'" i18n="@@website.faq.accessibilityBusiness">Is the course also suitable for people with dyslexia or a disability?</ng-container>
</button>
<div class="answerWrapper" [style.max-height.px]="isVisible ? answerHeight : 0">
    <div
        [ngSwitch]="questionName"
        class="answer"
        #answer
    >
        <ng-container *ngSwitchCase="'tablet'" i18n="@@website.faq.tabletAnswer">
            <p><strong>Yes!</strong> We do recommend connecting a separate keyboard.</p>
            <p>Want to make sure that the screen resolution of your device is high enough? Try it out in our free trial version.</p>
        </ng-container>
        <ng-container *ngSwitchCase="'age'" i18n="@@website.faq.ageAnswer">
            <p>There is no fixed minimum age. It is important that your child can read the instructions sufficiently.</p>
            <p>This usually works fine from 7-8 years old.</p>
        </ng-container>
        <ng-container *ngSwitchCase="'duration'" i18n="@@website.faq.durationAnswer">
            <p>You can complete the course at your own pace. The more you practice, the faster you will learn touch typing.</p>
            <p>On average, the completion time is about 8 weeks.</p>
            <p>Need more time? No problem, you have an entire year to complete the course.</p>
        </ng-container>
        <ng-container *ngSwitchCase="'dyslexia'" i18n="@@website.faq.dyslexiaAnswer">
            <p><strong>Yes!</strong> We use a font suitable for dyslexics in the course. Exercises are always displayed large enough to be easily readable.</p>
        </ng-container>
        <ng-container *ngSwitchCase="'limitation'" i18n="@@website.faq.limitationAnswer">
            <p><strong>Yes!</strong> We currently provide these accessibility options:</p>
            <ul>
                <li>Visually impaired or blind</li>
                <li>Colorblind</li>
                <li>Hard of hearing or deaf</li>
            </ul>
            <p>If you have dyslexia, the course is also suitable for you!</p>
        </ng-container>
        <ng-container *ngSwitchCase="'schoolNotYet'" i18n="@@website.faq.schoolNotYetAnswer">
            <p><strong>Yes!</strong> You can always order the individual version and complete the course independently.</p>
        </ng-container>
        <ng-container *ngSwitchCase="'costSchool'" i18n="@@website.faq.costSchoolAnswer">
            <p><strong>No!</strong> The tracking platform is completely free for schools!</p>
            <p>Teachers can also use the course for free.</p>
        </ng-container>
        <ng-container *ngSwitchCase="'costBusiness'" i18n="@@website.faq.costBusinessAnswer">
            <p><strong>Yes!</strong> The tracking platform is completely free for companies!</p>
            <p>Instructors can also use the course for free.</p>
        </ng-container>
        <ng-container *ngSwitchCase="'freeForInstructorsSchool'" i18n="@@website.faq.freeForInstructorsSchoolAnswer">
            <p>In order to track your students' progress properly, it is important that teachers also have access to the course themselves.</p>
            <p>Since they don't take the course themselves, we offer this access completely free of charge.</p>
        </ng-container>
        <ng-container *ngSwitchCase="'freeForInstructorsBusiness'" i18n="@@website.faq.freeForInstructorsBusinessAnswer">
            <p>In order to track your participants' progress properly, it is important that instructors also have access to the course themselves.</p>
            <p>Since they don't take the course themselves, we offer this access completely free of charge.</p>
        </ng-container>
        <ng-container *ngSwitchCase="'whoPaysSchool'" i18n="@@website.faq.whoPaysSchoolAnswer">
            <p>Both options are possible: either the school activates students' licenses, or the students do it themselves.</p>
            <p>Students at your school always enjoy the discounted rate, even if they order their own license!</p>
        </ng-container>
        <ng-container *ngSwitchCase="'whoPaysBusiness'" i18n="@@website.faq.whoPaysBusinessAnswer">
            <p>Both options are possible: either your company activates the licenses of participants, or your participants do it themselves.</p>
            <p>Employees of your company always enjoy the discounted rate, even if they order your own license!</p>
        </ng-container>
        <ng-container *ngSwitchCase="'accessSchool'" i18n="@@website.faq.accessSchoolAnswer">
            <p>A license gives unlimited access to the course for 365 days.</p>
            <p>The average time to complete is about 8 weeks, so there is always plenty of time to complete the course at a leisurely pace.</p>
        </ng-container>
        <ng-container *ngSwitchCase="'accessBusiness'" i18n="@@website.faq.accessBusinessAnswer">
            <p>A license gives unlimited access to the course for 365 days.</p>
            <p>The average time to complete is about 8 weeks, so there is always plenty of time to complete the course at a leisurely pace.</p>
        </ng-container>
        <ng-container *ngSwitchCase="'coachingSchool'" i18n="@@website.faq.coachingSchoolAnswer">
            <p>Your students can complete the course independently without problems. Everything is explained in great detail in how-to videos and interactive exercises.</p>
            <p>It is of course possible to provide extra guidance on top of that. In the tracking platform teachers see the work points of each student at a glance.</p>
        </ng-container>
        <ng-container *ngSwitchCase="'coachingBusiness'" i18n="@@website.faq.coachingBusinessAnswer">
            <p>Your participants can complete the course independently without problems. Everything is explained in great detail in how-to videos and interactive exercises.</p>
            <p>It is of course possible to provide extra guidance on top of that. In the tracking platform instructors see the work points of each participant at a glance.</p>
        </ng-container>
        <ng-container *ngSwitchCase="'classicalSchool'" i18n="@@website.faq.classicalSchoolAnswer">
            <p><strong>Definitely!</strong> The course is completely online, allowing students to practice on any computer or tablet/iPad.</p>
        </ng-container>
        <ng-container *ngSwitchCase="'classicalBusiness'" i18n="@@website.faq.classicalBusinessAnswer">
            <p><strong>Definitely!</strong> The course is completely online, so you can practice on any computer or tablet/iPad.</p>
        </ng-container>
        <ng-container *ngSwitchCase="'accessibilitySchool'" i18n="@@website.faq.accessibilitySchoolAnswer">
            <p><strong>Yes!</strong> We use a font that is suitable for dyslexics in the course. Instructions are always displayed large enough.</p>
            <p>We currently also provide these accessibility options:</p>
            <ul>
                <li>Visually impaired or blind</li>
                <li>Color blind</li>
                <li>Hard of hearing or deaf</li>
            </ul>
        </ng-container>
        <ng-container *ngSwitchCase="'accessibilityBusiness'" i18n="@@website.faq.accessibilityBusinessAnswer">
            <p><strong>Yes!</strong> We use a font that is suitable for dyslexics in the course. Instructions are always displayed large enough.</p>
            <p>We currently also provide these accessibility options:</p>
            <ul>
                <li>Visually impaired or blind</li>
                <li>Color blind</li>
                <li>Hard of hearing or deaf</li>
            </ul>
        </ng-container>
    </div>
</div>
