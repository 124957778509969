import {Injectable} from '@angular/core';
import {UserService} from './user.service';
import {map} from 'rxjs/operators';
import {OrganizationService} from './organization.service';
import {Observable, of} from 'rxjs';
import {LicenseService} from './license.service';
import {Menu} from '../../models/menu';
import {MenuItems} from '../../models/menuItems';
import {StateService} from '../../services/state.service';

@Injectable()
export class MenuService {
    public readonly footerMenu: Menu = [
        MenuItems.support,
        MenuItems.privacy,
        MenuItems.terms,
        MenuItems.contact
    ];

    public readonly guestMenu: Menu = [
        MenuItems.tryCourse,
        MenuItems.forSchools,
        MenuItems.forBusiness,
        MenuItems.purchase
    ];

    public readonly studentMenu: Menu = [
        MenuItems.course,
        MenuItems.progress
    ];

    public readonly studentTrialMenu: Menu = [
        MenuItems.course,
        MenuItems.progress,
        '|',
        MenuItems.purchase
    ];

    public readonly instructorMenu: Menu = [
        MenuItems.course,
        MenuItems.progress,
        '|',
        MenuItems.controlPanel
    ];

    public readonly instructorPanelMenu: Menu = [
        MenuItems.dashboard,
        MenuItems.myClasses,
        MenuItems.myExercises
    ];

    public readonly salesPersonMenu: Menu = [
        MenuItems.salesOrganizations,
        MenuItems.salesSales
    ];

    public readonly administratorMenu: Menu = [
        MenuItems.course,
        MenuItems.progress,
        '|',
        MenuItems.controlPanel
    ];

    public readonly administratorPanelMenu: Menu = [
        MenuItems.dashboard,
        MenuItems.classes,
        MenuItems.students,
        MenuItems.admins,
        MenuItems.myExercises,
        MenuItems.licenses,
        MenuItems.billing,
        MenuItems.settings
    ];

    public readonly supremeLeaderMenu: Menu = [
        MenuItems.course,
        MenuItems.progress,
        '|',
        MenuItems.supremeLeaderControlPanel
    ];

    public readonly supremeLeaderPanelMenu: Menu = [
        MenuItems.orgs,
        MenuItems.users,
        MenuItems.exercises,
        MenuItems.sales,
        MenuItems.discountCodes,
        MenuItems.stats,
        MenuItems.billingAdmin
    ];

    constructor(
        private userService: UserService,
        private organizationService: OrganizationService,
        private licenseService: LicenseService,
        private stateService: StateService
    ) {}

    getMenu(isFooter = false, isPanel = false, isMobile = false): Observable<Menu> {
        if (isFooter) {
            return of(this.footerMenu);
        }

        return this.stateService.getActiveUserObservable().pipe(
            map(
                (user) => {
                    let roleId = 0;
                    if (user) {
                        roleId = user.roleId;
                    }

                    switch (roleId) {
                        case 1:
                            if (isPanel) {
                                return this.supremeLeaderPanelMenu;
                            } else if (isMobile) {
                                let items = this.supremeLeaderMenu.filter(
                                    item => !(typeof item !== 'string' && 'id' in item && item.id === 'controlPanel')
                                );
                                items = [...items, ...this.supremeLeaderPanelMenu];

                                return items;
                            }
                            return this.supremeLeaderMenu;

                        case 2:
                            if (isPanel) {
                                return this.administratorPanelMenu;
                            } else if (isMobile) {
                                let items = this.administratorMenu.filter(
                                    item => !(typeof item !== 'string' && 'id' in item && item.id === 'controlPanel')
                                );
                                items = [...items, ...this.administratorPanelMenu];

                                return items;
                            }
                            return this.administratorMenu;

                        case 3:
                            if (isPanel) {
                                return this.instructorPanelMenu;
                            } else if (isMobile) {
                                let items = this.instructorMenu.filter(
                                    item => !(typeof item !== 'string' && 'id' in item && item.id === 'controlPanel')
                                );
                                items = [...items, ...this.instructorPanelMenu];

                                return items;
                            }
                            return this.instructorMenu;

                        case 4:
                            if (
                                this.licenseService.checkLicenseValid(
                                    this.stateService.getActiveUser().longestValidLicenseUntil
                                )
                            ) {
                                return this.studentMenu;
                            } else {
                                return this.studentTrialMenu;
                            }

                        case 5:
                            return this.salesPersonMenu;

                        default:
                            return this.guestMenu;
                    }
                }
            )
        );
    }
}
