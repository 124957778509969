<course-purchase-individual [products]="products"></course-purchase-individual>

<div *ngIf="!mainProduct || (!mainProduct.school && !mainProduct.business)" class="organizations">
    <div class="websiteSectionTitle">
        <h2 i18n="@@website.shop.schoolOrBusiness">School or business?</h2>
        <p
            i18n="@@website.shop.schoolOrBusinessSub"
        >Then you benefit from extra advantageous rates and you can use the tracking platform for free!</p>
    </div>

    <div class="orgTypes">
        <div class="school slideUp">
            <div class="img"></div>
            <div class="productDescription">
                <div class="descr">
                    <h3 i18n="@@website.shop.school">School</h3>
                    <p
                        i18n="@@website.shop.schoolInfo"
                    >With the free platform for schools it becomes really simple to monitor your students' progress.</p>
                </div>

                <p class="cta">
                    <a
                        [routerLink]="'/' + navigationService.getRouteTranslation('forSchools')"
                        mat-stroked-button
                        class="infoBtn"
                        i18n="@@moreInfo"
                    >More info</a>
                    <a
                        routerLink="/school-registration"
                        mat-flat-button
                        class="productBtn"
                        i18n="@@website.startFree"
                    >Start for free</a>
                </p>
            </div>
        </div>

        <div class="business slideUp">
            <div class="img"></div>
            <div class="productDescription">
                <div class="descr">
                    <h3 i18n="@@website.shop.business">Business</h3>
                    <p
                        i18n="@@website.shop.businessInfo"
                    >Enroll your employees in the most fun typing course and increase the productivity in your company!</p>
                </div>

                <p class="cta">
                    <a
                        [routerLink]="'/' + navigationService.getRouteTranslation('forBusinesses')"
                        mat-stroked-button
                        class="infoBtn"
                        i18n="@@moreInfo"
                    >More info</a>
                    <a
                        routerLink="/business-registration"
                        mat-flat-button
                        class="productBtn"
                        i18n="@@website.startFree"
                    >Start for free</a>
                </p>
            </div>
        </div>
    </div>
</div>

<course-features parentPage="purchase"></course-features>
