<div
    courseMeta
    [class.ssr]="!appService.isBrowser"
>
    <mat-sidenav-container>
        <mat-sidenav mode="push" position="end" #sidenav class="sidenav" role="navigation">
            <course-mobile-menu (closeMenu)="sidenav.close()"></course-mobile-menu>
        </mat-sidenav>
        <mat-sidenav-content role="main">
            <div class="content">
                <course-menu
                    *ngIf="!activityLayout && !checkoutLayout"
                    (openMobileNav)="sidenav.open()"
                ></course-menu>

                <div
                    [class.wrapper]="!activityLayout && !checkoutLayout && !panelLayout && !disableContentWrapper"
                    [class.highContrast]="highContrast | async"
                >
                    <router-outlet></router-outlet>
                </div>
            </div>

            <course-footer *ngIf="!activityLayout && !checkoutLayout && !panelLayout"></course-footer>
        </mat-sidenav-content>
    </mat-sidenav-container>

    <a
        *ngIf="!activityLayout"
        (click)="openCookieSettings()"
        class="cookieSettingsButton"
        i18n-matTooltip="@@cookieConsent.cookieSettings"
        matTooltip="Cookie settings"
        matTooltipPosition="left"
    >
        <img
            ngSrc="assets/images/cookieConsent/cookie.svg"
            width="1080"
            height="1080"
            i18n-alt="@@cookieConsent.cookieAlt"
            alt="Cookie"
        />
    </a>

    <course-modals></course-modals>
    <course-loading></course-loading>
    <course-loading-error *ngIf="stateService.getHasLoadingErrorObservable() | async"></course-loading-error>
</div>
