import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {ModalComponent} from './components/modals/modal/modal.component';
import {UpIconComponent} from './components/upIcon/upIcon.component';
import {FormatCurrencyPipe} from './pipes/formatCurrency.pipe';
import {TypeOfPipe} from './pipes/typeOf.pipe';
import {MatIconModule} from '@angular/material/icon';
import {RouterModule} from '@angular/router';
import {KeyboardSettingsComponent} from './components/keyboardSettings/keyboardSettings.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {IndividualComponent} from './components/individual/individual.component';
import {SupportService} from './services/support.service';
import {KeyboardLayoutService} from './services/keyboardLayout.service';
import {CharacterKeysService} from './services/characterKeys.service';
import {CourseService} from './services/course.service';
import {ExerciseService} from './services/exercise.service';
import {ResultService} from './services/result.service';
import {LessonService} from './services/lesson.service';
import {ChapterService} from './services/chapter.service';
import {AudioService} from './services/audio.service';
import {KeyboardLayoutFactory} from './services/factories/keyboardLayout.factory';
import {CourseFactory} from './services/factories/course.factory';
import {ChapterFactory} from './services/factories/chapter.factory';
import {ExerciseFactory} from './services/factories/exercise.factory';
import {ResultFactory} from './services/factories/result.factory';
import {TrackingBlockFactory} from './services/factories/trackingBlock.factory';
import {CharacterTrackingFactory} from './services/factories/characterTracking.factory';
import {LessonFactory} from './services/factories/lesson.factory';
import {ClassFactory} from './services/factories/class.factory';
import {UserFactory} from './services/factories/user.factory';
import {UserService} from './services/user.service';
import {OrganizationService} from './services/organization.service';
import {DiscountCodeService} from './services/discountCode.service';
import {ClassService} from './services/class.service';
import {LicenseService} from './services/license.service';
import {DownloadService} from './services/download.service';
import {StatisticService} from './services/statistic.service';
import {SaleService} from './services/sale.service';
import {OrderService} from './services/order.service';
import {OrganizationFactory} from './services/factories/organization.factory';
import {ImportBatchFactory} from './services/factories/importBatch.factory';
import {UserStatsFactory} from './services/factories/userStats.factory';
import {OrganizationSettingsFactory} from './services/factories/organizationSettings.factory';
import {SaleFactory} from './services/factories/sale.factory';
import {AppService} from './services/app.service';
import {LanguageService} from './services/language.service';
import {CountryService} from './services/country.service';
import {ProductService} from './services/product.service';
import {CurrencyService} from './services/currency.service';
import {ContactService} from './services/contact.service';
import {MenuService} from './services/menu.service';
import {NavigationService} from './services/navigation.service';
import {ModalService} from './services/modal.service';
import {BadgeService} from './services/badge.service';
import {ProductFactory} from './services/factories/product.factory';
import {OrderFactory} from './services/factories/order.factory';
import {DiscountCodeFactory} from './services/factories/discountCode.factory';
import {CurrencyFactory} from './services/factories/currency.factory';
import {LanguageFactory} from './services/factories/language.factory';
import {KnownOrganizationFactory} from './services/factories/knownOrganization.factory';
import {BadgeFactory} from './services/factories/badge.factory';
import {AdminService} from './services/admin.service';
import {HttpClientModule} from '@angular/common/http';
import {MistakeFactory} from './services/factories/mistake.factory';
import {FormatDurationPipe} from './pipes/formatDuration.pipe';


const components = [
    // Pipes
    FormatCurrencyPipe,
    TypeOfPipe,
    FormatDurationPipe,

    // Components
    ModalComponent,
    UpIconComponent,
    KeyboardSettingsComponent,
    IndividualComponent,
];

@NgModule({
    declarations: components,
    exports: components,
    imports: [
        CommonModule,
        RouterModule,
        HttpClientModule,
        NgOptimizedImage,

        // Material modules
        MatIconModule,
        MatSlideToggleModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatButtonModule,
        MatTooltipModule
    ],
    providers: [
        // Factories
        ProductFactory,
        OrderFactory,
        DiscountCodeFactory,
        CurrencyFactory,
        LanguageFactory,
        KnownOrganizationFactory,
        BadgeFactory,
        KeyboardLayoutFactory,
        CourseFactory,
        ChapterFactory,
        ExerciseFactory,
        ResultFactory,
        TrackingBlockFactory,
        CharacterTrackingFactory,
        LessonFactory,
        UserFactory,
        ClassFactory,
        OrganizationFactory,
        ImportBatchFactory,
        UserStatsFactory,
        OrganizationSettingsFactory,
        SaleFactory,
        MistakeFactory,

        // Services
        AppService,
        LanguageService,
        CountryService,
        ProductService,
        CurrencyService,
        OrderService,
        ContactService,
        MenuService,
        NavigationService,
        ModalService,
        BadgeService,
        SupportService,
        KeyboardLayoutService,
        CharacterKeysService,
        CourseService,
        ExerciseService,
        ResultService,
        LessonService,
        ChapterService,
        AudioService,
        UserService,
        OrganizationService,
        DiscountCodeService,
        ClassService,
        LicenseService,
        DownloadService,
        StatisticService,
        SaleService,
        AdminService
    ]
})
export class SharedModule {
}
