import {Injectable, Injector} from '@angular/core';
import {environment} from '../../../environments/environment';
import {catchError, map, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {TrackingBlock} from '../../models/trackingBlock';
import {ResultFactory} from './factories/result.factory';
import {CourseFactory} from './factories/course.factory';
import {Observable} from 'rxjs';
import {Result} from '../../models/result';
import {Highscore} from '../../models/highscore';
import {UserService} from './user.service';
import {BadgeFactory} from './factories/badge.factory';
import {DownloadService} from './download.service';
import {AppService} from './app.service';
import {StateService} from '../../services/state.service';
import {BadgeService} from './badge.service';
import {Mistake} from '../../models/mistake';

@Injectable()
export class ResultService {
    constructor(
        private http: HttpClient,
        private resultFactory: ResultFactory,
        private userService: UserService,
        private injector: Injector,
        private stateService: StateService,
        private badgeFactory: BadgeFactory,
        private downloadService: DownloadService,
        private appService: AppService,
        private badgeService: BadgeService
    ) {
    }

    getOne(id: number): Observable<Result> {
        return this.http.get<Result>(environment.apiUrl + 'results/' + id)
            .pipe(
                map(
                    (response) => {
                        return this.resultFactory.map(response);
                    }
                )
            );
    }

    /**
     * Saves a result either on the api (logged in) or localstorage (not logged in).
     * If a resultId is passed, the result is updated (only on api), else created.
     * ResultId is ignored when not logged in: we don't log attempts in localstorage,
     * as custom exercises are not available to guest users.
     */
    saveResult(
        resultId: number,
        exerciseId: number,
        completed: boolean,
        mistakes?: Mistake[],
        duration?: number,
        data?: string,
        score?: number | string
    ): Observable<Result> {
        if (score) {
            // double base64 encode the score
            score = btoa(btoa('' + score));
        }

        return this.http.put<any>(environment.apiUrl + 'results/save', {
            result_id: resultId,
            exercise_id: exerciseId,
            mistakes,
            duration,
            completed,
            data,
            score
        }).pipe(
            tap(
                (response) => {
                    if (response.earned_badges.length > 0) {
                        this.stateService.setEarnedBadges(
                            response.earned_badges.map(badge => this.badgeFactory.map(badge))
                        );

                        this.badgeService.addUnseenBadges(response.earned_badges.length);
                    }
                }
            ),
            map(
                (response) => {
                    return this.resultFactory.map(response.result);
                }
            ),
            tap(
                (result) => {
                    // not logged in? save in localstorage
                    if (!this.stateService.getActiveUser()) {
                        const results = this.getLocalResults();

                        results.push(result);

                        // save in localstorage
                        localStorage.setItem(
                            'course.results',
                            this.encodeLocal(
                                JSON.stringify(results)
                            )
                        );
                    }
                }
            )
        );
    }

    getHighscores(exerciseId: number, score: number) {
        return this.http.get<any>(environment.apiUrl + 'results/highscores/' + exerciseId + '/' + score)
            .pipe(
                map(
                    (response) => {
                        return new Highscore(
                            response.is_personal_best,
                            response.personal_best,
                            response.is_best_of_group,
                            response.better_than_percentage
                        );
                    }
                )
            );
    }

    getLocalResults() {
        let results = [];
        if (localStorage.getItem('course.results')) {
            results = JSON.parse(
                this.decodeLocal(
                    localStorage.getItem('course.results')
                )
            );
        }

        return results;
    }

    encodeLocal(results: string): string {
        return btoa(btoa(results));
    }

    decodeLocal(resultString: string): string {
        return atob(atob(resultString));
    }

    getOverviewResults() {
        return this.http.get<any>(environment.apiUrl + 'results/overview');
    }

    countExerciseResults(id: number) {
        return this.http.get<any>(environment.apiUrl + 'results/count_for_exercise/' + id);
    }

    getKeyboardKnowledge(id?: number) {
        let url = environment.apiUrl + 'results/keyboard_knowledge';
        if (id) {
            url += '/' + id;
        }

        return this.http.get<any>(url);
    }

    getUserProgress(id: number) {
        const courseFactory = this.injector.get(CourseFactory);

        let url = environment.apiUrl + 'results/user_progress';
        if (id) {
            url += '/' + id;
        }

        return this.http.get<any>(url).pipe(
            map(
                (result) => {
                    // secondsDuration to hours & minutes
                    let minutes: number | string = Math.round(result.secondsDuration / 60);
                    let hours: number | string = 0;
                    if (minutes > 59) {
                        hours = Math.floor(minutes / 60);
                        minutes = minutes % 60;
                    }

                    // zero pad
                    hours = String(hours).padStart(2, '0');
                    minutes = String(minutes).padStart(2, '0');

                    return {
                        ...result,
                        duration: hours + ':' + minutes,
                        cpm: Math.round(result.cpm),
                        correctRatio: Math.round(result.correctRatio * 100) / 100
                    };
                }
            )
        );
    }

    getActivityLog(
        classId: number,
        userId: number | string,
        exerciseIds: number[],
        page: number,
        pageSize: number,
        sortBy: string,
        sortDirection: string
    ) {
        let ex = 'undefined';
        if (exerciseIds.length > 0) {
            ex = exerciseIds.join(',');
        }

        return this.http.get<any>(
            environment.apiUrl + 'results/activity_log/' + classId + '/' + userId + '/' + ex
            + '?page=' + page
            + '&page-size=' + pageSize
            + '&sort-by=' + sortBy
            + '&sort-direction=' + sortDirection
        );
    }

    getClassStats(
        classId: number,
        page: number,
        pageSize: number,
        sortBy: string,
        sortDirection: string
    ) {
        return this.http.get<any>(
            environment.apiUrl + 'results/group_stats/' + classId
            + '?page=' + page
            + '&page-size=' + pageSize
            + '&sort-by=' + sortBy
            + '&sort-direction=' + sortDirection
        );
    }

    getDashboardStats() {
        return this.http.get<any>(environment.apiUrl + 'results/dashboard_stats');
    }

    getLocalCompletedExerciseIds() {
        const completedExerciseIds = this.getLocalResults()
            .filter(result => !!result.completed)
            .map(result => result.exerciseId);

        return [... new Set(completedExerciseIds)];
    }

    /**
     * Get the last completed exercise id from localstorage
     */
    getLocalLastResult() {
        const results = this.getLocalResults();

        if (!results || results.length < 1) {
            return undefined;
        }

        return results.reduce((prev, current) => {
            return (prev.createdAt > current.createdAt) ? prev : current;
        }).exerciseId;
    }

    clearLocalResults() {
        localStorage.removeItem('course.results');
    }

    transferLocalProgress() {
        const resultString = localStorage.getItem('course.results');

        return this.http.post<any>(environment.apiUrl + 'results/transfer_local_progress', {
            result_string: resultString
        }).pipe(
            tap(
                () => {
                    this.clearLocalResults();
                }
            )
        );
    }

    downloadCertificate(id) {
        return this.downloadService.downloadFile('results/certificate/' + id);
    }

    downloadClassCertificates(id) {
        return this.downloadService.downloadFile('results/class_certificates/' + id).pipe(
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }
}
