export class OrganizationSettings {
    constructor(
        public organizationId: number,
        public courseId: number,
        public languageId: number,
        public allowUpdateAccountDetails: boolean,
        public allowUpdatePassword: boolean,
        public allowUpdateCourse: boolean,
        public showKeyboard: boolean,
        public allowBackspace: boolean,
        public appleKeyboard: boolean
    ) {}
}
