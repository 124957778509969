import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Country} from '../../../models/country';
import {ActivatedRoute} from '@angular/router';
import {auditTime, catchError, distinctUntilChanged, first} from 'rxjs/operators';
import {BehaviorSubject, NEVER} from 'rxjs';
import {MatTabGroup} from '@angular/material/tabs';
import {KnownOrganization} from '../../../models/knownOrganization';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {MatSnackBar} from '@angular/material/snack-bar';
import {OrganizationTypes} from '../../../models/abstract/organizationTypes';
import {OrganizationService} from '../../../shared/services/organization.service';
import {LanguageService} from '../../../shared/services/language.service';
import {AppService} from '../../../shared/services/app.service';
import {CountryService} from '../../../shared/services/country.service';
import {NavigationService} from '../../../shared/services/navigation.service';
import {AnalyticsService} from '../../../services/analytics.service';

@Component({
    selector: 'course-organization-registration',
    templateUrl: './organizationRegistration.component.html',
    styleUrls: ['./organizationRegistration.component.scss'],
})
export class OrganizationRegistrationComponent extends OnDestroyMixin implements OnInit {
    protected readonly OrganizationTypes = OrganizationTypes;
    @ViewChild('tabs') tabGroup: MatTabGroup;
    success = false;
    errors: { [field: string]: string[] } = {};
    countries: Country[];
    isSchool = false;
    formPages = [
        'school',
        'administrator',
        'details'
    ];
    pageNames = {
        school: $localize `:@@organizationRegistration.pages.school:School`,
        business: $localize `:@@organizationRegistration.pages.business:Business`,
        details: $localize `:@@organizationRegistration.pages.details:Details`,
        administrator: $localize `:@@organizationRegistration.pages.administrator:Administrator`
    };
    organizationTypes = [];
    pageIndex = 0;
    validatedPages = {
        0: false,
        1: false,
        2: false
    };
    knownOrganizations: BehaviorSubject<KnownOrganization[]> = new BehaviorSubject<KnownOrganization[]>([]);
    selectedKnownOrganization: KnownOrganization;
    form: FormGroup<{
        organization: FormGroup<{
            countryCode: FormControl<string | null>;
            organizationTypeId: FormControl<number>;
            name: FormControl<string | number | null>;
            address: FormControl<string | null>;
            state: FormControl<string | null>;
            postalCode: FormControl<string | null>;
            city: FormControl<string | null>;
        }>;
        user: FormGroup<{
            firstName: FormControl<string | null>;
            name: FormControl<string | null>;
            email: FormControl<string | null>;
            password: FormControl<string | null>;
            optIn: FormControl<boolean | null>;
        }>;
        details: FormGroup<{
            taxNumber: FormControl<string | null>;
            billingEmail: FormControl<string | null>;
            acceptTerms: FormControl<boolean | null>;
            optIn: FormControl<boolean | null>;
        }>;
    }>;
    hidePassword = true;

    constructor(
        private activatedRoute: ActivatedRoute,
        private organizationService: OrganizationService,
        private languageService: LanguageService,
        private appService: AppService,
        public countryService: CountryService,
        private snackBar: MatSnackBar,
        public navigationService: NavigationService,
        private analyticsService: AnalyticsService
    ) {
        super();
    }

    ngOnInit() {
        this.isSchool = this.activatedRoute.snapshot.data.isSchool;
        this.countries = this.activatedRoute.snapshot.data.countries;
        this.organizationTypes = this.activatedRoute.snapshot.data.organizationTypes.filter(
            type => type.school === this.isSchool
        );

        this.initForm();

        if (this.isSchool) {
            this.form.get('organization.name').valueChanges.pipe(
                auditTime(500),
                distinctUntilChanged(),
                untilComponentDestroyed(this)
            ).subscribe(
                (value: string) => {
                    this.searchKnownOrganizations(value);
                }
            );
        }
    }

    displayKnownOrganization(id: number) {
        const org = this.getSelectedKnownOrganization(id);

        if (org) {
            return org.name;
        }

        return '';
    }

    getSelectedKnownOrganization(id) {
        const res = this.knownOrganizations.getValue().filter(
            knownOrganization => knownOrganization.id === id
        );

        if (res.length === 1) {
            return res[0];
        }
    }

    searchKnownOrganizations(value: string | number) {
        if (typeof value === 'number') {
            const org = this.getSelectedKnownOrganization(value);
            if (org) {
                value = org.name;
            }
        }

        if (value === '') {
            this.knownOrganizations.next([]);
            return;
        }

        const countryCode = this.form.get('organization.countryCode').value;
        const organizationTypeId = this.form.get('organization.organizationTypeId').value;

        this.organizationService.searchKnownOrganizations('' + value, countryCode, organizationTypeId).pipe(
            first()
        ).subscribe(
            (knownOrganizations: KnownOrganization[]) => {
                this.knownOrganizations.next(knownOrganizations);
            }
        );
    }

    selectKnownOrganization() {
        const id = this.form.get('organization.name').value;

        this.selectedKnownOrganization = this.getSelectedKnownOrganization(id);

        // update known fields
        this.form.get('organization.address').setValue(this.selectedKnownOrganization.address);
        this.form.get('organization.postalCode').setValue(this.selectedKnownOrganization.postalCode);
        this.form.get('organization.state').setValue(this.selectedKnownOrganization.state);
        this.form.get('organization.city').setValue(this.selectedKnownOrganization.city);
        this.form.get('details.taxNumber').setValue(this.selectedKnownOrganization.taxNumber);
    }

    initForm() {
        const defaultCountryCode = this.appService.getDomainDefaults().countryCode;

        this.form = new FormGroup({
            organization: new FormGroup({
                countryCode: new FormControl<string | null>(defaultCountryCode),
                organizationTypeId: new FormControl<number | null>(this.isSchool ? null : 1),
                name: new FormControl<string | number | null>(''),
                address: new FormControl<string | null>(''),
                state: new FormControl<string | null>(''),
                postalCode: new FormControl<string | null>(''),
                city: new FormControl<string | null>('')
            }),
            user: new FormGroup({
                firstName: new FormControl<string | null>(''),
                name: new FormControl<string | null>(''),
                email: new FormControl<string | null>(''),
                password: new FormControl<string | null>(''),
                optIn: new FormControl<boolean | null>(false)
            }),
            details: new FormGroup({
                taxNumber: new FormControl<string | null>(''),
                billingEmail: new FormControl<string | null>(''),
                acceptTerms: new FormControl<boolean | null>(false),
                optIn: new FormControl<boolean | null>(false)
            })
        });
    }

    showErrorSnackbar() {
        this.snackBar.open(
            $localize `:@@organizationRegistration.errorsFound:Please correct the errors`,
            '',
            {
                duration: 5000,
                panelClass: ['errorSnackbar']
            }
        );
    }

    markAccountFieldsUntouched() {
        this.form.get('user.firstName').setErrors(null);
        this.form.get('user.name').setErrors(null);
        this.form.get('user.email').setErrors(null);
        this.form.get('user.password').setErrors(null);
    }

    markDetailsFieldsUntouched() {
        this.form.get('details.taxNumber').setErrors(null);
        this.form.get('details.billingEmail').setErrors(null);
        this.form.get('details.acceptTerms').setErrors(null);
        this.form.get('details.optIn').setErrors(null);
    }

    validateOrganizationDetails() {
        // mark other page fields as untouched
        this.markAccountFieldsUntouched();
        this.markDetailsFieldsUntouched();

        // organization name
        let name = this.form.get('organization.name').value;
        if (typeof name !== 'string') {
            const org = this.getSelectedKnownOrganization(name);
            name = org.name;
        }

        // let api validate org details
        return this.organizationService.validateOrganizationDetails(
            this.form.get('organization.countryCode').value,
            this.form.get('organization.organizationTypeId').value,
            name,
            this.form.get('organization.address').value,
            this.form.get('organization.state').value,
            this.form.get('organization.postalCode').value,
            this.form.get('organization.city').value
        ).pipe(
            first(),
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.errors = err.error.errors;

                        if (err.error.errors) {
                            this.setOrganizationErrors(err);
                            this.showErrorSnackbar();
                        }
                    }

                    return NEVER;
                }
            )
        );
    }

    validateAccountDetails() {
        // mark other page fields as untouched
        this.markDetailsFieldsUntouched();

        // let api validate org details
        return this.organizationService.validateAccountDetails(
            this.form.get('user.firstName').value,
            this.form.get('user.name').value,
            this.form.get('user.email').value,
            this.form.get('user.password').value
        ).pipe(
            first(),
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.errors = err.error.errors;

                        if (err.error.errors) {
                            this.setAccountErrors(err);
                            this.showErrorSnackbar();
                        }
                    }

                    return NEVER;
                }
            )
        );
    }

    submit() {
        let name = this.form.get('organization.name').value;
        if (typeof name !== 'string') {
            const org = this.getSelectedKnownOrganization(name);
            name = org.name;
        }

        this.organizationService.create(
            this.isSchool,
            this.form.get('organization.countryCode').value,
            this.form.get('organization.organizationTypeId').value,
            name,
            this.form.get('organization.address').value,
            this.form.get('organization.state').value,
            this.form.get('organization.postalCode').value,
            this.form.get('organization.city').value,
            this.form.get('user.firstName').value,
            this.form.get('user.name').value,
            this.form.get('user.email').value,
            this.form.get('user.password').value,
            this.form.get('details.billingEmail').value,
            this.form.get('details.taxNumber').value,
            this.form.get('details.optIn').value,
            this.form.get('details.acceptTerms').value,
            this.languageService.getActiveLanguage().id
        ).pipe(
            first(),
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.errors = err.error.errors;

                        if (err.error.errors) {
                            this.setOrganizationErrors(err);
                            this.setAccountErrors(err);

                            this.showErrorSnackbar();

                            this.form.get('details.taxNumber').markAsTouched();
                            this.form.get('details.taxNumber').setErrors(err.error.errors.tax_number);

                            this.form.get('details.billingEmail').markAsTouched();
                            this.form.get('details.billingEmail').setErrors(err.error.errors.billing_email);

                            this.form.get('details.acceptTerms').markAsTouched();
                            this.form.get('details.acceptTerms').setErrors(err.error.errors.accept_terms);

                            this.form.get('details.optIn').markAsTouched();
                            this.form.get('details.optIn').setErrors(err.error.errors.opt_in);
                        }
                    }

                    return NEVER;
                }
            )
        ).subscribe(
            () => {
                this.errors = {};
                this.success = true;

                this.analyticsService.trackConversion('organizationRegistration');
            }
        );

        return false;
    }

    setOrganizationErrors(err) {
        this.validatedPages[0] = false;

        this.form.get('organization.countryCode').markAsTouched();
        this.form.get('organization.countryCode').setErrors(err.error.errors.country_code);

        this.form.get('organization.organizationTypeId').markAsTouched();
        this.form.get('organization.organizationTypeId').setErrors(err.error.errors.organization_type_id);

        this.form.get('organization.name').markAsTouched();
        this.form.get('organization.name').setErrors(err.error.errors.name);

        this.form.get('organization.address').markAsTouched();
        this.form.get('organization.address').setErrors(err.error.errors.address);

        this.form.get('organization.state').markAsTouched();
        this.form.get('organization.state').setErrors(err.error.errors.state);

        this.form.get('organization.postalCode').markAsTouched();
        this.form.get('organization.postalCode').setErrors(err.error.errors.postal_code);

        this.form.get('organization.city').markAsTouched();
        this.form.get('organization.city').setErrors(err.error.errors.city);
    }

    setAccountErrors(err) {
        this.validatedPages[1] = false;

        this.form.get('user.firstName').markAsTouched();
        this.form.get('user.firstName').setErrors(err.error.errors.first_name);

        this.form.get('user.name').markAsTouched();
        this.form.get('user.name').setErrors(err.error.errors.name);

        this.form.get('user.email').markAsTouched();
        this.form.get('user.email').setErrors(err.error.errors.email);

        this.form.get('user.password').markAsTouched();
        this.form.get('user.password').setErrors(err.error.errors.password);
    }

    getCountryName(code: string) {
        return this.countries.filter(
            country => country.code === code
        )[0].name;
    }

    getPrevious() {
        let previous = this.pageIndex;

        if (this.pageIndex > 0) {
            previous -= 1;
        }

        return previous;
    }

    getNext() {
        let next = this.pageIndex;

        if (this.pageIndex < 2) {
            next += 1;
        }

        return next;
    }

    back() {
        this.pageIndex = this.getPrevious();
    }

    continue() {
        this.validateAndGoTo(
            this.getNext()
        );
    }

    validateAndGoTo(goToIndex: number) {
        switch (this.pageIndex) {
            case 0:
                this.validateOrganizationDetails().subscribe(
                    () => {
                        this.validatedPages[0] = true;
                        this.pageIndex = goToIndex;
                    }
                );
                break;

            case 1:
                this.validateAccountDetails().subscribe(
                    () => {
                        this.validatedPages[1] = true;
                        this.pageIndex = goToIndex;
                    }
                );
                break;
        }
    }

    goToPage(goToIndex: number) {
        // can't jump to a page if it and its previous page aren't yet validated
        if (!this.validatedPages[goToIndex] && !this.validatedPages[goToIndex - 1]) {
            return;
        }

        if (goToIndex < this.pageIndex) {
            // don't validate when going back
            this.pageIndex = goToIndex;
        } else {
            this.validateAndGoTo(goToIndex);
        }
    }
}

