import {Injectable, Injector} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {AppService} from './app.service';
import {ClassFactory} from './factories/class.factory';
import {UserFactory} from './factories/user.factory';

@Injectable()
export class ClassService {
    constructor(
        private http: HttpClient,
        private appService: AppService,
        private classFactory: ClassFactory,
        private injector: Injector
    ) {}

    getOne(id: number | string) {
        return this.http.get<any>(environment.apiUrl + 'groups/' + id).pipe(
            map(
                (response) => {
                    return this.classFactory.map(response);
                }
            )
        );
    }

    getBySelfJoinCode(selfJoinCode: string) {
        return this.http.get<any>(environment.apiUrl + 'groups/self_join/' + selfJoinCode).pipe(
            map(
                (response) => {
                    return this.classFactory.map(response);
                }
            )
        );
    }

    getForOrganization(
        organizationId: number | string,
        instructorId: number,
        page: number,
        pageSize: number,
        sortBy: string,
        sortDirection: string,
        filter: string,
        includeArchived = false
    ) {
        let url = environment.apiUrl + 'groups/for_organization/' + organizationId;

        if (instructorId) {
            url += '/' + instructorId;
        }

        if (!filter) {
            filter = '';
        }

        return this.http.get<any>(
            url
            + '?page=' + page
            + '&page-size=' + pageSize
            + '&sort-by=' + sortBy
            + '&sort-direction=' + sortDirection
            + '&include-archived=' + includeArchived
            + '&filter=' + filter
        );
    }

    countMine() {
        // NOTE: archived classes are also counted here
        return this.http.get<number>(environment.apiUrl + 'groups/count_for_organization/mine');
    }

    create(
        organizationId: string | number | undefined,
        name: string,
        instructorIds: number[]
    ) {
        return this.http.post<any>(environment.apiUrl + 'groups/create', {
            organization_id: organizationId,
            name,
            instructor_ids: instructorIds
        }).pipe(
            map(
                (response) => {
                    return this.classFactory.map(response);
                }
            ),
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    update(
        id: number,
        name: string,
        instructorIds: number[]
    ) {
        return this.http.patch<any>(environment.apiUrl + 'groups/' + id, {
            name,
            instructor_ids: instructorIds
        }).pipe(
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    archive(id: number) {
        return this.http.post(environment.apiUrl + 'groups/archive/' + id, {});
    }

    unarchive(id: number) {
        return this.http.post(environment.apiUrl + 'groups/unarchive/' + id, {});
    }

    delete(id: number) {
        return this.http.delete(environment.apiUrl + 'groups/' + id);
    }

    linkInstructor(instructorId: number, classIds: number[]) {
        return this.http.post<any>(environment.apiUrl + 'groups/link_instructor', {
            instructor_id: instructorId,
            group_ids: classIds
        }).pipe(
            map(
                (response) => {
                    const userFactory = this.injector.get(UserFactory);
                    return userFactory.map(response);
                }
            ),
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }
}
