<ul class="checklist">
    <li>
        <span
            i18n="@@website.shop.business1"
        ><strong>{{ product?.discountOnFullPrice }}% extra discount</strong> for <strong>participants</strong></span>
    </li>
    <li>
        <span
            i18n="@@website.shop.business2"
        ><strong>No fixed costs</strong></span>
    </li>
    <li>
        <span
            i18n="@@website.shop.business3"
        >Advanced <strong>tracking</strong></span>
    </li>
</ul>
