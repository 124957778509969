import {Injectable} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';
import {ModalService} from '../shared/services/modal.service';
import {AppService} from '../shared/services/app.service';

declare const gtag: any;
// declare const fbq: any;

export interface AnalyticsEvent {
    type: 'PAGEVIEW' | 'CONVERSION';
    label: string;
    currency?: string;
    value?: number;
}

@Injectable()
export class AnalyticsService {
    eventsQueue$ = new ReplaySubject<AnalyticsEvent>();

    constructor(
        private router: Router,
        private modalService: ModalService,
        private appService: AppService
    ) {
    }

    public init(): void {
        if (!this.appService.isBrowser) {
            return;
        }

        this.initConsent();
        this.initRouteChanges();

        this.subscribeToAnalyticalEvents();
        this.subscribeToMarketingEvents();
    }

    initConsent(): void {
        const hasAnalyticalConsent = localStorage.getItem('cookieConsentAnalytical');
        const hasMarketingConsent = localStorage.getItem('cookieConsentMarketing');

        if (hasAnalyticalConsent === null || hasMarketingConsent === null) {
            this.askConsent();
        } else {
            if (hasAnalyticalConsent === '1') {
                this.startTrackingAnalytical();
            }

            if (hasMarketingConsent === '1') {
                this.startTrackingMarketing();
            }
        }
    }

    public initRouteChanges(): void {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.trackVirtualPageview(event.urlAfterRedirects);
            }
        });
    }

    public askConsent() {
        this.modalService.setModal('cookieConsent');
    }

    public setConsent(analytical: boolean, marketing: boolean) {
        this.setAnalyticalConsent(analytical);
        this.setMarketingConsent(marketing);

        if (analytical || marketing) {
            // send a pageview event for the current page
            this.trackVirtualPageview(this.router.url);
        }
    }

    private setAnalyticalConsent(hasConsent: boolean): void {
        localStorage.setItem('cookieConsentAnalytical', hasConsent ? '1' : '0');

        if (hasConsent) {
            // if previously disabled -> enable again
            window['ga-disable-G-829E88VTFE'] = false;

            // start tracking
            this.startTrackingAnalytical();
        } else {
            // stop tracking
            window['ga-disable-G-829E88VTFE'] = true;

            // clear analytics cookies that might have already been set
            this.deleteCookie('_ga', '/', location.hostname);
            this.deleteCookie('_ga_829E88VTFE', '/', location.hostname);
        }
    }

    private setMarketingConsent(hasConsent: boolean): void {
        localStorage.setItem('cookieConsentMarketing', hasConsent ? '1' : '0');

        if (hasConsent) {
            // start tracking
            this.startTrackingMarketing();
        } else {
            // facebook pixel
            // fbq('consent', 'revoke');
        }
    }

    public startTrackingAnalytical(): void {
        // google analytics
        gtag('js', new Date());
        gtag(
            'config',
            'G-829E88VTFE',
            {
                send_page_view: false
            }
        );
    }

    public startTrackingMarketing(): void {
        // facebook pixel
        // fbq('init', '875675557537869');
        // fbq('consent', 'grant');
    }

    private subscribeToAnalyticalEvents(): void {
        this.eventsQueue$.subscribe((e: AnalyticsEvent) => {
            if (localStorage.getItem('cookieConsentAnalytical') !== '1') {
                return;
            }

            if (e.type === 'PAGEVIEW') {
                gtag('event', 'page_view', {
                    page_title: e.label,
                });
            } else if (e.type === 'CONVERSION') {
                gtag('event', e.label);
            }
        });
    }

    private subscribeToMarketingEvents(): void {
        this.eventsQueue$.subscribe((e: AnalyticsEvent) => {
            if (localStorage.getItem('cookieConsentMarketing') !== '1') {
                return;
            }

            if (e.type === 'PAGEVIEW') {
                // fbq('track', 'PageView');
            } else if (e.type === 'CONVERSION') {
                switch (e.label) {
                    case 'individualCheckout':
                        /*
                         Note: we could track this, but the Facebook ad manager doesn't allow filtering on
                         the content_name.
                         We need the CompleteRegistration event to track organization registrations,
                         so we don't track it here.
                         */
                        // fbq('track', 'CompleteRegistration', {content_name: 'individualCheckout'});
                        break;

                    case 'individualSale':
                        // fbq('track', 'Purchase', {content_name: 'individualSale', currency: e.currency, value: e.value});
                        break;

                    case 'organizationRegistration':
                        // fbq('track', 'CompleteRegistration', {content_name: 'organizationRegistration'});
                        break;
                }
            }
        });
    }

    private trackVirtualPageview(name: string): void {
        this.eventsQueue$.next({type: 'PAGEVIEW', label: name});
    }

    public trackConversion(label: string, currency = 'EUR', value = 0) {
        this.eventsQueue$.next({type: 'CONVERSION', label, currency, value});
    }

    private getCookie(name: string) {
        return document.cookie.split(';').some(c => {
            return c.trim().startsWith(name + '=');
        });
    }

    private deleteCookie(name: string, path: string, domain: string) {
        if (this.getCookie(name)) {
            document.cookie = name + '=' +
                ((path) ? ';path=' + path : '') +
                ((domain) ? ';domain=' + domain : '') +
                ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
        }
    }
}
