import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {ProductFactory} from './factories/product.factory';
import {environment} from '../../../environments/environment';
import {Product} from '../../models/product';

@Injectable()
export class ProductService {
    private productTypes: {
        individual: Product,
        school: Product,
        business: Product
    };

    constructor(
        private http: HttpClient,
        private productFactory: ProductFactory
    ) {}

    getOne(id): Observable<Product> {
        return this.http.get<any>(environment.apiUrl + 'products/' + id)
            .pipe(
                map(
                    (response) => {
                        return this.productFactory.map(response);
                    }
                )
            );
    }

    getMine(countryCode: string = null, hasTaxNr: boolean = null): Observable<Product> {
        return this.http.get<any>(environment.apiUrl + 'products/mine/' + countryCode + '/' + hasTaxNr)
            .pipe(
                map(
                    (response) => {
                        return this.productFactory.map(response);
                    }
                )
            );
    }

    getForOrganization(id: number): Observable<Product> {
        return this.http.get<any>(environment.apiUrl + 'products/for_organization/' + id)
            .pipe(
                map(
                    (response) => {
                        return this.productFactory.map(response);
                    }
                )
            );
    }

    getAll() {
        return this.http.get<any>(environment.apiUrl + 'products/all').pipe(
            map(
                (response) => {
                    return response.map(
                        (product) => {
                            return this.productFactory.map(product);
                        }
                    );
                }
            )
        );
    }

    getAllTypes() {
        // use cached version if it exists
        if (this.productTypes) {
            return of(this.productTypes);
        }

        return this.http.get<any>(environment.apiUrl + 'products/all_types').pipe(
            map(
                (response) => {
                    const types = {};
                    for (const type of Object.keys(response)) {
                        types[type] = this.productFactory.map(response[type]);
                    }

                    return types;
                }
            ),
            tap(
                (productTypes: {individual: Product, school: Product, business: Product}) => {
                    this.productTypes = productTypes;
                }
            )
        );
    }
}
