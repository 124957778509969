import {Component, Input} from '@angular/core';
import {Product} from '../../../../models/product';

@Component({
    selector: 'course-business-features',
    templateUrl: './businessFeatures.component.html',
    styleUrls: ['./businessFeatures.component.scss'],
})
export class BusinessFeaturesComponent {
    @Input() product: Product;
}

