<div class="container">
    <div class="head">
        <a routerLink="/" class="logo">
            <img
                ngSrc="assets/images/layout/logo.svg"
                i18n-alt="@@website.logoAlt"
                alt="Tyyp logo"
                width="285.42"
                height="80"
            />
        </a>

        <div class="tabNav" *ngIf="!success">
            <a
                *ngFor="let page of formPages; let i = index" class="formPageLink"
                (click)="goToPage(i)"
                [class.active]="i === pageIndex"
                [class.completed]="validatedPages[i]"
            >
                <div class="graphic">
                    <div class="lineLeft"></div>
                    <div class="lineRight"></div>
                    <div class="ball">
                        <div></div>
                    </div>
                </div>
                <div class="label">
                    {{ pageNames[page] }}
                </div>
            </a>
        </div>

        <div class="filler"></div>
    </div>

    <div class="content">
        <div class="formContainer">
            <ng-container *ngIf="success">
                <div class="formSectionHeader">
                    <div class="websiteSectionTitle">
                        <h2 i18n="@@organizationRegistration.success">Registration successful!</h2>
                        <p
                            *ngIf="isSchool"
                            i18n="@@organizationRegistration.successInfoSchool"
                        >We have sent your login details to your e-mail. After logging in you can start adding your students!</p>
                        <p
                            *ngIf="!isSchool"
                            i18n="@@organizationRegistration.successInfoBusiness"
                        >We have sent your login details to your e-mail. After logging in you can start adding your participants!</p>
                    </div>

                    <img
                        ngSrc="assets/images/typingCourse/signUpFinal.png"
                        class="heroImg"
                        width="483"
                        height="373"
                        alt=""
                    />
                </div>

                <div class="spamboxInfo" i18n="@@purchase.spamBox">
                    <p><strong>Didn't receive the e-mail?</strong> Sometimes e-mails arrive with a little delay. If our e-mail doesn't reach you within <strong>30 minutes</strong>, check if it didn't accidentaly end up in your <strong>spambox</strong>!</p>
                    <p>
                        Still didn't receive the e-mail?
                        <a [routerLink]="'/' + navigationService.getRouteTranslation('contact')">Send us a message</a>
                        , we're happy to help!
                    </p>
                </div>
            </ng-container>

            <ng-container *ngIf="!success">
                <form
                    [formGroup]="form"
                    (submit)="pageIndex < 2 ? continue() : submit()"
                >
                    <div formGroupName="organization" [hidden]="pageIndex !== 0">
                        <div class="formSectionHeader">
                            <div class="websiteSectionTitle">
                                <h2 i18n="@@organizationRegistration.organizationTitle">Get started with Tyyp!</h2>
                                <p
                                    *ngIf="isSchool"
                                    i18n="@@organizationRegistration.organizationSubSchool"
                                >Fill in your school details below and get instant free access to the school platform</p>
                                <p
                                    *ngIf="!isSchool"
                                    i18n="@@organizationRegistration.organizationSubBusiness"
                                >Fill in your business details below and get instant free access to the business platform</p>
                            </div>

                            <img
                                ngSrc="assets/images/typingCourse/{{ isSchool ? 'school' : 'business' }}.svg"
                                class="heroImg"
                                width="556.21"
                                height="429.59"
                                alt=""
                            />
                        </div>

                        <h3
                            *ngIf="isSchool"
                            i18n="@@organizationRegistration.schoolDetails"
                        >About your school</h3>
                        <h3
                            *ngIf="!isSchool"
                            i18n="@@organizationRegistration.businessDetails"
                        >About your company</h3>
                        <p>
                            <mat-form-field appearance="outline">
                                <mat-label i18n="@@country">Country</mat-label>
                                <mat-select required formControlName="countryCode" #countrySelect>
                                    <mat-select-trigger>
                                        <img
                                            ngSrc="assets/images/flags/{{ countrySelect.value.toLowerCase() }}.svg"
                                            class="flag"
                                            width="512"
                                            height="512"
                                            [attr.alt]="countrySelect.value.toLowerCase()"
                                        />
                                        {{ getCountryName(countrySelect.value) }}
                                    </mat-select-trigger>
                                    <mat-option *ngFor="let country of countries" [value]="country.code">
                                        <img
                                            ngSrc="assets/images/flags/{{ country.code.toLowerCase() }}.svg"
                                            class="flag"
                                            width="512"
                                            height="512"
                                            [attr.alt]="country.code.toLowerCase()"
                                        />
                                        {{ country.name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="errors?.country_code">
                                    <ng-container *ngFor="let err of errors.country_code">
                                        {{ err }} <br>
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                        </p>

                        <p [hidden]="!isSchool">
                            <mat-form-field appearance="outline">
                                <mat-label i18n="@@organizationRegistration.schoolType">Education type</mat-label>
                                <mat-select required formControlName="organizationTypeId">
                                    <mat-option *ngFor="let type of organizationTypes" [value]="type.id">
                                        {{ OrganizationTypes[type.name] }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="errors?.organization_type_id">
                                    <ng-container *ngFor="let err of errors.organization_type_id">
                                        {{ err }} <br>
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                        </p>

                        <h3 i18n="@@address">Address</h3>
                        <p>
                            <mat-form-field appearance="outline">
                                <mat-label *ngIf="isSchool" i18n="@@organizationRegistration.schoolName">School name</mat-label>
                                <mat-label *ngIf="!isSchool" i18n="@@purchase.companyName">Company name</mat-label>
                                <input matInput required formControlName="name" [matAutocomplete]="auto">
                                <mat-autocomplete
                                    #auto="matAutocomplete"
                                    [displayWith]="displayKnownOrganization.bind(this)"
                                    (optionSelected)="selectKnownOrganization()"
                                >
                                    <mat-option *ngFor="let option of (knownOrganizations | async)" [value]="option.id">
                                        {{ option.name }} (<strong>{{ option.city }}</strong> - <em>{{ option.address }}</em>)
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-error *ngIf="errors?.name">
                                    <ng-container *ngFor="let err of errors.name">
                                        {{ err }} <br>
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                        </p>
                        <p>
                            <mat-form-field appearance="outline">
                                <mat-label i18n="@@address">Address</mat-label>
                                <input matInput required formControlName="address">
                                <mat-error *ngIf="errors?.address">
                                    <ng-container *ngFor="let err of errors.address">
                                        {{ err }} <br>
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                        </p>

                        <p class="formRow">
                            <span id="stateField" [class.invisible]="!countryService.countryHasStates(form.get('organization.countryCode').value)">
                                <mat-form-field appearance="outline">
                                    <mat-label i18n="@@stateProvince">State / province</mat-label>
                                    <input matInput formControlName="state">
                                    <mat-hint>Optional</mat-hint>
                                    <mat-error *ngIf="errors?.state">
                                        <ng-container *ngFor="let err of errors.state">
                                            {{ err }} <br>
                                        </ng-container>
                                    </mat-error>
                                </mat-form-field>
                            </span>

                            <span id="postalCodeField">
                                <mat-form-field appearance="outline">
                                    <mat-label i18n="@@postalCode">Postal code</mat-label>
                                    <input matInput required formControlName="postalCode">
                                    <mat-error *ngIf="errors?.postal_code">
                                        <ng-container *ngFor="let err of errors.postal_code">
                                            {{ err }} <br>
                                        </ng-container>
                                    </mat-error>
                                </mat-form-field>
                            </span>

                            <mat-form-field appearance="outline">
                                <mat-label i18n="@@city">City</mat-label>
                                <input matInput required formControlName="city">
                                <mat-error *ngIf="errors?.city">
                                    <ng-container *ngFor="let err of errors.city">
                                        {{ err }} <br>
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                        </p>

                        <!-- invisible button to make submit with enter key work -->
                        <button
                            type="submit"
                            class="invisible"
                        ></button>
                    </div>

                    <div formGroupName="user" [hidden]="pageIndex !== 1">
                        <div class="formSectionHeader">
                            <div class="websiteSectionTitle">
                                <h2
                                    i18n="@@organizationRegistration.accountTitle"
                                >Administrator account details</h2>
                                <p
                                    *ngIf="isSchool"
                                    i18n="@@organizationRegistration.accountSubSchool"
                                >Administrators can add classes and students. You can add additional administrators and teachers later!</p>
                                <p
                                    *ngIf="!isSchool"
                                    i18n="@@organizationRegistration.accountSubBusiness"
                                >Administrators can add groups and participants. You can add additional administrators and instructors later!</p>
                            </div>

                            <img
                                ngSrc="assets/images/mascotte/administrator.png"
                                class="heroImg"
                                width="483"
                                height="373"
                                alt=""
                            />
                        </div>

                        <p class="formRow">
                            <mat-form-field appearance="outline">
                                <mat-label i18n="@@firstName">First name</mat-label>
                                <input matInput required formControlName="firstName">
                                <mat-error *ngIf="errors?.first_name">
                                    <ng-container *ngFor="let err of errors.first_name">
                                        {{ err }} <br>
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                        </p>

                        <p class="formRow">
                            <mat-form-field appearance="outline">
                                <mat-label i18n="@@name">Name</mat-label>
                                <input matInput required formControlName="name">
                                <mat-error *ngIf="errors?.name">
                                    <ng-container *ngFor="let err of errors.name">
                                        {{ err }} <br>
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                        </p>

                        <p>
                            <mat-form-field appearance="outline">
                                <mat-label i18n="@@email">E-mail</mat-label>
                                <input type="email" matInput required formControlName="email">
                                <mat-error *ngIf="errors?.email">
                                    <ng-container *ngFor="let err of errors.email">
                                        {{ err }} <br>
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                        </p>

                        <p>
                            <mat-form-field appearance="outline">
                                <mat-label i18n="@@activateLicense.choosePassword">Choose a password</mat-label>
                                <input
                                    matInput
                                    required
                                    [type]="hidePassword ? 'password' : 'text'"
                                    tabindex="1"
                                    formControlName="password"
                                    autocomplete="new-password"
                                />
                                <button
                                    type="button"
                                    mat-icon-button
                                    matSuffix
                                    (click)="hidePassword = !hidePassword"
                                    [attr.aria-label]="'Hide password'"
                                    [attr.aria-pressed]="hidePassword"
                                >
                                    <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
                                </button>
                                <mat-error *ngIf="errors?.password">
                                    <ng-container *ngFor="let err of errors.password">
                                        {{ err }} <br>
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                        </p>

                        <!-- invisible button to make submit with enter key work -->
                        <button
                            type="submit"
                            class="invisible"
                        ></button>
                    </div>

                    <div formGroupName="details" [hidden]="pageIndex !== 2">
                        <div class="formSectionHeader">
                            <div class="websiteSectionTitle">
                                <h2 i18n="@@organizationRegistration.detailsTitle">Almost there!</h2>
                                <p
                                    i18n="@@organizationRegistration.detailsSub"
                                >Just a few more details before you can really get started!</p>
                            </div>

                            <img
                                ngSrc="assets/images/typingCourse/signUpFinal.png"
                                class="heroImg"
                                width="483"
                                height="373"
                                alt=""
                            />
                        </div>

                        <div class="billingInfo">
                            <ng-container *ngIf="isSchool" i18n="@@organizationRegistration.billingNoticeSchool">
                                <p>We only use your billing information when you purchase licenses for your students.</p>
                                <p>The platform is completely free for schools! <strong>No charges will incur upon registration!</strong></p>
                            </ng-container>
                            <ng-container *ngIf="!isSchool" i18n="@@organizationRegistration.billingNoticeBusiness">
                                <p>We only use your billing information when you purchase licenses for your participants.</p>
                                <p>The platform is completely free for businesses! <strong>No charges will incur upon registration!</strong></p>
                            </ng-container>
                        </div>
                        <p>
                            <mat-form-field appearance="outline">
                                <mat-label i18n="@@purchase.taxNumber">Tax number</mat-label>
                                <input matInput [required]="!isSchool" formControlName="taxNumber">
                                <mat-hint *ngIf="isSchool" i18n="@@optional">Optional</mat-hint>
                                <mat-error *ngIf="errors?.tax_number">
                                    <ng-container *ngFor="let err of errors.tax_number">
                                        {{ err }} <br>
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                        </p>

                        <p>
                            <mat-form-field appearance="outline">
                                <mat-label i18n="@@organizationRegistration.billingEmail">Billing e-mail</mat-label>
                                <input type="email" matInput required formControlName="billingEmail">
                                <mat-error *ngIf="errors?.billing_email">
                                    <ng-container *ngFor="let err of errors.billing_email">
                                        {{ err }} <br>
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                        </p>

                        <p>
                            <mat-checkbox
                                required
                                formControlName="acceptTerms"
                                i18n="@@acceptTerms"
                            >I have read and accept the <a routerLink="/terms" target="_blank">Terms of Service</a> and <a routerLink="/privacy" target="_blank">Privacy Policy</a>.</mat-checkbox>
                            <mat-error *ngIf="errors?.accept_terms">
                                <ng-container *ngFor="let err of errors.accept_terms">
                                    {{ err }} <br>
                                </ng-container>
                            </mat-error>
                        </p>

                        <p>
                            <mat-checkbox
                                formControlName="optIn"
                                i18n="@@optIn"
                            >I want to receive important updates about Tyyp by e-mail.</mat-checkbox>
                        </p>

                        <!-- invisible button to make submit with enter key work -->
                        <button
                            type="submit"
                            class="invisible"
                        ></button>
                    </div>
                </form>
            </ng-container>
        </div>
    </div>
</div>

<div class="nav">
    <div class="navContent">
        <div class="leftNav">
            <a
                *ngIf="!success && pageIndex > 0"
                (click)="back()"
                mat-flat-button
                color="primary"
                class="backBtn"
                i18n="@@previous"
            >Previous</a>

            <a *ngIf="success" routerLink="/" mat-flat-button color="primary" class="backBtn">
                <span>
                    <mat-icon>west</mat-icon>
                    <ng-container i18n="@@organizationRegistration.backToHome">Homepage</ng-container>
                </span>
            </a>
        </div>

        <div class="rightNav">
            <ng-container *ngIf="!success">
                <a *ngIf="pageIndex < 2" (click)="continue()" mat-flat-button color="accent" class="submitBtn">
                    <span>
                        <ng-container i18n="@@continue">Continue</ng-container>
                        <mat-icon class="buttonRightIcon">east</mat-icon>
                    </span>
                </a>
                <a *ngIf="pageIndex === 2" (click)="submit()" mat-flat-button color="accent" class="submitBtn">
                    <span>
                        <mat-icon>check</mat-icon>
                        <ng-container i18n="@@save">Save</ng-container>
                    </span>
                </a>
            </ng-container>

            <a *ngIf="success" routerLink="/login" mat-flat-button color="accent" class="submitBtn">
                <span i18n="@@login.title">Log in</span>
            </a>
        </div>
    </div>
</div>
