<div class="individual" [class.endOfTrial]="endOfTrial" [class.trialCTA]="trialCTA">
    <div
        class="img"
        [class.slideRight]="stateService.getFirstHeroAnimationSkipped()"
    >
        <div class="circle"></div>
        <img
            ngSrc="assets/images/shop/product.webp"
            i18n-alt="@@website.shop.productAlt"
            alt="Typing course product"
            width="500"
            height="412"
        />
    </div>

    <div
        class="description"
        [class.slideLeft]="stateService.getFirstHeroAnimationSkipped()"
    >
        <h1 i18n="@@website.shop.individualTitle">Get started with Tyyp!</h1>
        <p
            i18n="@@website.shop.individualInfo"
        >Complete the course at your own pace. Everything is explained in great detail, so you can learn touch typing in no time.</p>
        <ul class="checklist">
            <li *ngIf="endOfTrial">
                <span
                    i18n="@@website.shop.individualKeepProgress"
                >Your <strong>progress</strong> of the trial version <strong>will be saved</strong></span>
            </li>
            <li>
                <span
                    i18n="@@website.shop.individual1"
                ><strong>Valid for a whole year</strong></span>
            </li>
            <li>
                <span
                    i18n="@@website.shop.individual2"
                >Up to <strong>80% cheaper</strong> than alternatives</span>
            </li>
            <li>
                <span
                    i18n="@@website.shop.individual3"
                >Get <strong>instant access</strong></span>
            </li>
        </ul>

        <div class="purchaseInfo">
            <div *ngIf="product?.discountOnFullPrice > 0" class="discount">
                <p>
                    <span class="discountPercentage">-{{ product.discountOnFullPrice }}%</span>
                    <ng-container *ngIf="product.school" i18n="@@organization.studentDiscount">student discount</ng-container>
                    <ng-container *ngIf="product.business" i18n="@@organization.companyDiscount">company discount</ng-container>
                    <ng-container *ngIf="!product.school && !product.business">
                        {{ product.saleName ? product.saleName[languageService.getActiveLanguage().code] : '' }}
                    </ng-container>
                </p>
            </div>

            <div class="priceCta">
                <div class="pricing">
                    <span class="price">
                        <span *ngIf="product?.discountOnFullPrice > 0" class="originalPrice">
                            <span i18n="@@msrp" class="msrp">MSRP</span> <span class="priceNr">{{ product.fullPriceIncl | formatCurrency:currency }}</span>
                        </span>
                        <span class="priceNumber" [innerHTML]="product ? (product.calculatedIncl | formatCurrency:currency:true) : '&nbsp;'"></span>
                        <span class="exclIncl">
                            <ng-container i18n="@@incl">incl.</ng-container>
                            <ng-container i18n="@@taxes">taxes</ng-container>
                        </span>
                    </span>
                </div>

                <div class="orderBtn">
                    <button (click)="addToCart()" class="keyboardButton">
                        <span i18n="@@website.shop.order">Buy now</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
