import {Component, OnInit} from '@angular/core';
import {catchError, first} from 'rxjs/operators';
import {NEVER} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {Class} from '../../../models/class';
import {ActivatedRoute} from '@angular/router';
import {UserService} from '../../../shared/services/user.service';
import {CourseService} from '../../../shared/services/course.service';
import {LanguageService} from '../../../shared/services/language.service';

@Component({
    selector: 'course-self-join',
    templateUrl: './selfJoin.component.html',
    styleUrls: ['./selfJoin.component.scss'],
})
export class SelfJoinComponent implements OnInit {
    errors: { [field: string]: string[] } = {};
    emailAlreadyExists = false;
    success = false;
    courseId: number;
    class: Class;
    form: FormGroup<{
        selfJoinCode: FormControl<string | null>;
        email: FormControl<string | null>;
        firstName: FormControl<string | null>;
        name: FormControl<string | null>;
        acceptTerms: FormControl<boolean | null>;
    }>;


    constructor(
        private userService: UserService,
        private courseService: CourseService,
        private languageService: LanguageService,
        private activatedRoute: ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.class = this.activatedRoute.snapshot.data.class;

        this.form = new FormGroup({
            selfJoinCode: new FormControl<string | null>(this.class.selfJoinCode),
            email: new FormControl<string | null>(''),
            firstName: new FormControl<string | null>(''),
            name: new FormControl<string | null>(''),
            acceptTerms: new FormControl<boolean | null>(false)
        });
    }

    submit() {
        this.userService.selfJoin(
            this.class.selfJoinCode,
            this.form.controls.email.value,
            this.form.controls.firstName.value,
            this.form.controls.name.value,
            this.languageService.getActiveLanguage().id,
            this.form.controls.acceptTerms.value
        ).pipe(
            first(),
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.errors = err.error.errors;

                        if (err.error.errors) {
                            this.form.controls.selfJoinCode.setErrors(err.error.errors.self_join_code);
                            this.form.controls.email.setErrors(err.error.errors.email);
                            this.form.controls.firstName.setErrors(err.error.errors.first_name);
                            this.form.controls.name.setErrors(err.error.errors.name);
                            this.form.controls.acceptTerms.setErrors(err.error.errors.accept_terms);
                        }

                        if (err.error === 'emailAlreadyExists') {
                            this.emailAlreadyExists = true;
                        }
                    }

                    return NEVER;
                }
            )
        ).subscribe(
            () => {
                this.errors = {};
                this.success = true;
            }
        );

        return false;
    }
}

